import React, { useEffect, useState } from 'react'
import { SelectProps } from 'baseui/select'
import authenticatedFetch from 'components/utils/authenticated-fetch'
import { useDebounce } from 'react-use'
import pluralize from 'pluralize'
import Select from 'components/ui/generic/Select'

type CyberAutocompleteProps = {
  record: any
  setRecord: (any) => void
  indexName: string
  recordIsString?: boolean
  label?: string
} & SelectProps

export const CyberAutocomplete = ({
  record,
  setRecord,
  indexName,
  recordIsString = false,
  label,
  ...props
}: CyberAutocompleteProps) => {
  const indexNameId = `${indexName}Id`
  const snakeCaseIndexNameId = `${indexName}_id`
  const [search, setSearch] = useState<string>(null)
  const [tempSearch, setTempSearch] = useState<string>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [hits, setHits] = useState([])

  useEffect(() => {
    if (record && (record[indexNameId] || record[snakeCaseIndexNameId])) {
      setLoading(true)
      const id = record[indexNameId] || record[snakeCaseIndexNameId]
      const controller = pluralize(indexName)
      fetchData(controller, id)
    }
  }, [])

  useEffect(() => {
    if (record && recordIsString) {
      setLoading(true)
      fetchData(pluralize(indexName), record)
    }
  }, [record])

  const fetchData = (controller: string, id: string) => {
    authenticatedFetch({
      path: `/${controller}/${id}.json`
    }).then(([json, status]) => {
      if ([304, 200].includes(status)) {
        setSearch(json.name)
        setLoading(false)
      }
    })
  }

  const searchRecords = async () => {
    setLoading(true)
    const [{ hits }, status] = await authenticatedFetch({
      path: `/search.json`,
      method: 'POST',
      body: {
        search: {
          indexName,
          params: {
            query: search
          }
        }
      }
    })
    setLoading(false)
    if ([200, 304].includes(status)) {
      setHits(hits)
    }
  }

  useDebounce(searchRecords, 200, [search])

  return (
    <Select
      id={`${indexName}-select`}
      isLoading={loading}
      clearable={false}
      backspaceClearsInputValue
      maxDropdownHeight="250px"
      deleteRemoves={true}
      label={label}
      value={search ? [{ name: search, id: -1 }] : [{ name: '', id: null }]}
      onOpen={() => {
        setHits([])
        setLoading(true)
        setTempSearch(search)
        setSearch('')
      }}
      onClose={() => {
        if (!search) {
          setSearch(tempSearch)
        }
      }}
      onChange={({ option, type }) => {
        if (type == 'remove') {
          recordIsString ? setRecord(null) : setRecord({ ...record, [indexNameId]: null })
          setSearch('')
        } else {
          setSearch(option.name)
          recordIsString
            ? setRecord(option.id)
            : setRecord({
                ...record,
                [indexNameId]: option.id
              })
        }
      }}
      options={hits}
      labelKey="name"
      onInputChange={evt => {
        recordIsString ? setRecord(null) : setRecord({ ...record, [indexNameId]: null })
        setSearch(evt.currentTarget.value)
      }}
      {...props}
    />
  )
}
