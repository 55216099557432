import React from 'react'
import ButtonGroup from 'components/ui/specific/ButtonGroup'
import Select from 'components/ui/generic/Select'
import FormControl from 'components/ui/generic/FormControl'
import TrailerAutoComplete from './fields/TrailerAutoComplete'

// icon imports
import { AlignRight, BatteryEmpty, BatteryFull } from '@phosphor-icons/react'
import { useTranslation } from 'react-i18next'
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid'

const cargoStatusButtons = [
  {
    label: 'Full',
    value: 'full',
    icon: <BatteryFull size={16} />
  },
  {
    label: 'Empty',
    value: 'empty',
    icon: <BatteryEmpty size={16} />
  },
  {
    label: 'In-Dock',
    value: 'in-dock',
    icon: <AlignRight size={16} />
  }
]

const trailerStates = [
  { label: 'Damaged', id: 'damaged' },
  { label: 'Not-damaged', id: 'not-damaged' },
  { label: 'Detention', id: 'detention' }
]

const TrailerStatusSection = ({
  title,
  appointment,
  setAppointment,
  appointmentDirection,
  appointmentKey,
  oneColumn = false
}) => {
  const { t } = useTranslation()
  // FUNCTION TO FIND A VALUE IN AN ARRAY OF OBJECTS => RETURNS OBJECT OR EMPTY OBJECT
  const findValue = (arr, key, val) => arr.find(item => item[key] === val) || {}

  return (
    <FormControl>
      <>
        <ButtonGroup
          label={t('Appointments.CreateAppointmentModal.Fields.Trailer.CargoStatus.Label.Text', {
            type: title
          })}
          mode="radio"
          buttons={cargoStatusButtons}
          onChange={value => setAppointment('status', value)}
          value={appointment.trailer?.status}
        />
        <FlexGrid
          flexGridColumnCount={oneColumn ? 1 : [1, 1, 2]}
          flexGridRowGap="scale600"
          flexGridColumnGap="scale600">
          {/** TRAILER NUMBER SELECT */}
          <FlexGridItem>
            <TrailerAutoComplete
              title={title}
              appointmentDirection={appointmentDirection}
              onChange={value => setAppointment('number', value)}
              key={appointmentKey}
              trailerNumber={appointment.trailer?.number}
            />
          </FlexGridItem>
          {/** TRAILER STATE SELECT */}
          <FlexGridItem>
            <Select
              options={trailerStates}
              value={[findValue(trailerStates, 'id', appointment.trailer?.state)]}
              searchable={false}
              clearable={false}
              label={t('Appointments.CreateAppointmentModal.Fields.Trailer.State.Label.Text', {
                type: title
              })}
              valueKey="id"
              onChange={value => setAppointment('state', value.option.id)}
            />
          </FlexGridItem>
        </FlexGrid>
      </>
    </FormControl>
  )
}

export default TrailerStatusSection
