import React from 'react'
import { Block } from 'baseui/block'
import { LabelSmall, ParagraphSmall } from 'baseui/typography'
import { styled } from 'baseui'
import { ArrowLineDown, ArrowLineUp } from '@phosphor-icons/react'

const getNestedValue = (obj, path) => {
  const value = path.split('.').reduce((acc, part) => acc && acc[part], obj)
  if (path === 'appointmentType.type') {
    return value === 'InboundAppointmentType'
      ? 'Inbound'
      : value === 'OutboundAppointmentType'
      ? 'Outbound'
      : value
  }
  return value
}

const getIcon = obj => {
  return obj.appointmentType.type?.includes('Inbound') ? (
    <ArrowLineDown size={12} color="#082766" />
  ) : (
    <ArrowLineUp size={12} color="#082766" />
  )
}

const LabelField = ({
  pointer,
  field,
  appointment,
  capitalize = false,
  showIcon = false
}: {
  pointer: string
  field: string
  appointment: any
  capitalize?: boolean
  showIcon?: boolean
}) => {
  const value = getNestedValue(appointment, pointer) || 'N/A'
  const Icon = showIcon ? getIcon(appointment) : ''
  const CustomLabel1 = styled(ParagraphSmall, {
    color: '#04112B',
    fontSize: '12px',
    fontFamily: 'Inter',
    fontWeight: '400',
    lineHeight: '4px',
    whiteSpace: 'nowrap'
  })

  const CustomLabel = styled(LabelSmall, {
    // PL78976334
    color: '#04112B',
    fontSize: '15px',
    fontFamily: 'Inter',
    fontWeight: '500',
    lineHeight: '16px',
    whiteSpace: 'nowrap'
  })

  const CustomLabelCapitalized = styled(LabelSmall, {
    // PL78976334
    color: '#04112B',
    fontSize: '15px',
    fontFamily: 'Inter',
    fontWeight: '500',
    lineHeight: '16px',
    whiteSpace: 'nowrap',
    textTransform: 'capitalize'
  })

  return (
    <Block
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      width="100%"
      overrides={{
        Block: {
          style: {
            display: 'flex',
            flexWrap: 'wrap',
            width: '100%'
          }
        }
      }}>
      <CustomLabel1>{field}</CustomLabel1>
      {capitalize ? (
        <CustomLabelCapitalized>
          {value}
          {showIcon && Icon}
        </CustomLabelCapitalized>
      ) : (
        <CustomLabel>
          {value}
          {showIcon && Icon}
        </CustomLabel>
      )}
    </Block>
  )
}

export default LabelField
