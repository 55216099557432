import React, { useContext } from 'react'
import { Block } from 'baseui/block'
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid'
import { DatePicker } from 'baseui/datepicker'
import { INBOUND, OUTBOUND, useAppointmentContext } from 'components/contexts/appointment-context'
import { useFacilityContext } from 'components/contexts/facility-context'
import moment from 'moment-timezone'
import Checkbox from 'components/ui/generic/Checkbox'
import { CurrentUserContext } from 'components/homepage/current-user-context'
import { SHIPPER } from 'components/models/User'
import { ChevronDown, ChevronUp } from 'baseui/icon'
import { Button, KIND, SIZE } from 'baseui/button'
import TimePicker from 'components/ui/generic/TimePicker'
import { CheckboxRecurringProps } from './DateTime.styled'
import { DROP } from 'components/constants/handling_method'
import { useTranslation } from 'react-i18next'

export function DateTime() {
  const { state, actions } = useAppointmentContext()
  const {
    state: { facility }
  } = useFacilityContext()
  const { currentUser } = useContext(CurrentUserContext)
  const {
    appointments,
    handlingMethod,
    appointmentDirections,
    recurring: { showRecurring, checked: recurringChecked, showRecurringFields }
  } = state
  const { setAppointment, setIsTimeSet, setCheckedRecurring, setOpenRecurring } = actions
  const { t } = useTranslation()

  const isShowRecurring = showRecurring && handlingMethod !== DROP
  const isInternalUser = currentUser?.userType === SHIPPER

  // Suppose you want to handle time in a specific timezone
  const timezone = 'America/New_York'

  const handleDateChange = date => {
    const newDate = new Date(date)
    appointmentDirections.forEach(direction => {
      setAppointment({ arrivalTime: newDate }, direction)
      setIsTimeSet(true)
    })
  }

  const handleTimePickerChange = time => {
    const timeFormat = 'hh:mm A'
    const newTime = moment(time, timeFormat).tz(timezone)

    appointmentDirections.forEach(direction => {
      const currentDate = moment(appointments[direction].arrivalTime).tz(timezone)
      currentDate.hours(newTime.hours())
      currentDate.minutes(newTime.minutes())
      setAppointment({ arrivalTime: currentDate.toDate() }, direction)
    })
    setIsTimeSet(true)
  }

  const getCurrentArrivalTime = () => {
    if (appointmentDirections.includes(INBOUND) && appointmentDirections.includes(OUTBOUND)) {
      return appointments[INBOUND].arrivalTime || appointments[OUTBOUND].arrivalTime
    } else if (appointmentDirections.includes(INBOUND)) {
      return appointments[INBOUND].arrivalTime
    } else if (appointmentDirections.includes(OUTBOUND)) {
      return appointments[OUTBOUND].arrivalTime
    }
    return null
  }

  return (
    <Block width="97%" display="flex">
      <FlexGrid
        flexGridColumnCount={isInternalUser ? 1 : [1, 1, 2]}
        flexGridColumnGap="scale900"
        flexGridRowGap="scale600"
        width="100%">
        <FlexGridItem>
          <DatePicker
            value={getCurrentArrivalTime() ? new Date(getCurrentArrivalTime()) : null}
            onChange={({ date }) => handleDateChange(date)}
            placeholder={t('Appointments.CreateAppointmentModal.Fields.ArrivalDate.Label.Text')}
            formatString="MM/dd/yyyy"
          />
        </FlexGridItem>
        <FlexGridItem>
          <FlexGrid flexGridColumnCount={isShowRecurring ? 2 : 1}>
            <FlexGridItem>
              <TimePicker
                placeholder={t('Appointments.CreateAppointmentModal.Fields.ArrivalTime.Label.Text')}
                label={t('Appointments.CreateAppointmentModal.Fields.ArrivalTime.Label.Text')}
                step={facility.appointmentDuration || 1800}
                nullable
                value={getCurrentArrivalTime() ? new Date(getCurrentArrivalTime()) : null}
                onChange={params => handleTimePickerChange(params)}
                disabled={!getCurrentArrivalTime()}
              />
            </FlexGridItem>
            {isShowRecurring && (
              <FlexGridItem display={'flex'} alignItems={'center'} justifyContent={'center'}>
                <Checkbox
                  checked={recurringChecked}
                  onChange={e => setCheckedRecurring(e.target.checked)}
                  label={
                    <>{t('Appointments.CreateAppointmentModal.Fields.Recurring.Label.Text')}</>
                  }
                  {...CheckboxRecurringProps}
                />
                <Button
                  size={SIZE.mini}
                  kind={KIND.minimal}
                  onClick={() => {
                    setOpenRecurring(!showRecurringFields)
                  }}>
                  {showRecurringFields ? <ChevronDown size={24} /> : <ChevronUp size={24} />}
                </Button>
              </FlexGridItem>
            )}
          </FlexGrid>
        </FlexGridItem>
      </FlexGrid>
    </Block>
  )
}
