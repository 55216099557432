import React, { useEffect } from 'react'
import { connectRefinementList } from 'react-instantsearch-dom'
import { useTranslation } from 'react-i18next'
import Select from 'components/ui/generic/Select'
import { AppTypeKeys, useYardScheduleContext } from '../../contexts/yard-schedule.context'
import { Option } from '../../shared/types/Option'

export const NonConnectedYardScheduleAppTypeSelect = ({ refine }) => {
  const { t } = useTranslation()

  const {
    selectedFacility,
    selectedAppType,
    appTypeOptions,
    actions: { setAppType }
  } = useYardScheduleContext()

  useEffect(() => {
    if (selectedFacility) {
      const { appointmentTypes } = selectedFacility

      const allAppTypesIds = appointmentTypes.map(appType => appType.id)
      refine(allAppTypesIds)
      setAppType({ value: AppTypeKeys.ALL, label: AppTypeKeys.ALL })
    }
  }, [selectedFacility])

  const changeAppType = (option: Option<string>) => {
    setAppType(option)

    const { value } = option
    const { appointmentTypes } = selectedFacility

    if (value === AppTypeKeys.INBOUND) {
      const inboundAppTypesIds = appointmentTypes
        .filter(appType => appType.type === AppTypeKeys.INBOUND)
        .map(appType => appType.id)

      refine(inboundAppTypesIds)
    }

    if (value === AppTypeKeys.OUTBOUND) {
      const outboundAppTypesIds = appointmentTypes
        .filter(appType => appType.type === AppTypeKeys.OUTBOUND)
        .map(appType => appType.id)

      refine(outboundAppTypesIds)
    }

    if (value === AppTypeKeys.ALL) {
      const allAppTypesIds = appointmentTypes.map(appType => appType.id)

      refine(allAppTypesIds)
    }
  }

  return (
    <Select
      clearable={false}
      searchable={false}
      valueKey="value"
      options={appTypeOptions}
      value={selectedAppType.value === '' ? [] : [selectedAppType]}
      placeholder={t('Common.AppointmentTypeToggle.SelectAppointmentType.PlaceHolder.Text')}
      onChange={params => changeAppType(params?.value[0])}
    />
  )
}

const YardScheduleAppTypeSelectConnected = connectRefinementList(
  NonConnectedYardScheduleAppTypeSelect
)

const YardScheduleAppTypeSelect = () => {
  return (
    <YardScheduleAppTypeSelectConnected
      attribute="appointment_type_id"
      defaultRefinement={['appointment_type_id']}
    />
  )
}

export default YardScheduleAppTypeSelect
