import React, { useState } from 'react'

import { FlexGrid, FlexGridItem } from 'baseui/flex-grid'

import Header from 'components/ui/generic/Header'
import FormControl from 'components/ui/generic/FormControl'
import Input from 'components/ui/generic/Input'
import Button from 'components/ui/specific/PrimaryButton'
import { Breadcrumbs } from 'baseui/breadcrumbs'
import { StyledLink } from 'baseui/link'
import authenticatedFetch from '../utils/authenticated-fetch'
import { fancyToast } from '../utils'
import ColorPicker from './color-picker'
import FacilitiesSelect from './fields/facilities-select'
import { useHistory } from 'react-router-dom'
import Checkbox from 'components/ui/generic/Checkbox'
import { useTranslation } from 'react-i18next'
import { INBOUND_TYPE, OTHER_TYPE, OUTBOUND_TYPE } from 'components/constants/appointment_types'

const AppointmentTypeCreate = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const [appointmentType, setAppointmentType] = useState<any>({})
  const history = useHistory()
  const { t } = useTranslation()
  const createAppointmentType = async () => {
    setLoading(true)
    const [json, status] = await authenticatedFetch({
      path: `/appointment_types.json`,
      method: 'POST',
      body: {
        appointmentType
      }
    })

    if (status === 201) {
      fancyToast({ info: 'Appointment Type was successfully created' }, status)
      history.push('/settings/appointment_types')
    } else {
      fancyToast(json, status)
      setLoading(false)
    }
  }

  return (
    <>
      <Header
        title={
          <Breadcrumbs
            overrides={{
              ListItem: {
                style: ({ $theme }) => ({
                  fontFamily: $theme.typography.HeadingXSmall.fontFamily
                })
              }
            }}>
            <StyledLink href="/settings/appointment_types/">
              {t('Settings.AppointmentTypes.Header.Text')}
            </StyledLink>
            <span>{t('Settings.AppointmentTypes.Subtitle.Text')}</span>
          </Breadcrumbs>
        }
      />

      <FlexGrid maxWidth="640px">
        <FlexGridItem>
          <FormControl
            htmlFor="appointment_type[name]"
            label={t('Settings.AppointmentTypes.Details.Name')}>
            <Input
              id="appointment_type[name]"
              autoComplete="off"
              name="appointment_type[name]"
              value={appointmentType.name}
              onChange={e => {
                setAppointmentType({
                  ...appointmentType,
                  name: e.currentTarget.value
                })
              }}
              maxLength={30}
            />
          </FormControl>
        </FlexGridItem>
        <FlexGridItem>
          <FlexGrid flexGridColumnCount={2}>
            <FlexGridItem>
              <FormControl label={t('Settings.AppointmentTypes.Details.Color')}>
                <ColorPicker {...{ appointmentType, setAppointmentType }} />
              </FormControl>
            </FlexGridItem>
            <FlexGridItem>
              <FormControl label={t('Settings.AppointmentTypes.Details.Type')}>
                <FlexGrid>
                  <FlexGridItem>
                    <Checkbox
                      checked={appointmentType?.type === INBOUND_TYPE}
                      onChange={e => {
                        setAppointmentType({
                          ...appointmentType,
                          type: INBOUND_TYPE
                        })
                      }}
                      label={t('Settings.AppointmentTypes.Types.Inbound')}
                    />
                  </FlexGridItem>
                  <FlexGridItem>
                    <Checkbox
                      checked={appointmentType?.type === OUTBOUND_TYPE}
                      onChange={e => {
                        setAppointmentType({
                          ...appointmentType,
                          type: OUTBOUND_TYPE
                        })
                      }}
                      label={t('Settings.AppointmentTypes.Types.Outbound')}
                    />
                  </FlexGridItem>
                  <FlexGridItem>
                    <Checkbox
                      checked={appointmentType?.type === OTHER_TYPE}
                      onChange={e => {
                        setAppointmentType({
                          ...appointmentType,
                          type: OTHER_TYPE
                        })
                      }}
                      label={t('Settings.AppointmentTypes.Types.Other')}
                    />
                  </FlexGridItem>
                </FlexGrid>
              </FormControl>
            </FlexGridItem>
          </FlexGrid>
        </FlexGridItem>
        <FlexGridItem>
          <FacilitiesSelect {...{ appointmentType, setAppointmentType }} />
        </FlexGridItem>
        <FlexGridItem>
          <Button
            isLoading={loading}
            disabled={!appointmentType.name || !appointmentType.color || !appointmentType.type}
            onClick={createAppointmentType}>
            {t('Common.Button.Create.Text')}
          </Button>
        </FlexGridItem>
      </FlexGrid>
    </>
  )
}

export default AppointmentTypeCreate
