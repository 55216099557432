import React, { useState, useEffect, useContext } from 'react'

import { useStyletron } from 'baseui'
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid'

import { Tab } from 'baseui/tabs'

import FormControl from 'components/ui/generic/FormControl'
import Input from 'components/ui/generic/Input'
import Header from 'components/ui/generic/Header'
import Tabs from 'components/ui/generic/Tabs'
import Button from 'components/ui/generic/Button'

import { RemoveButton } from 'components/components/RemoveButton'
import { toast } from 'components/utils/toast'
import { appointmentTypeService } from 'components/services/appointment-type.service'
import Checkbox from 'components/ui/generic/Checkbox'
import { useForm } from 'react-hook-form'
import { CurrentUserContext } from 'components/homepage/current-user-context'
import { useTranslation } from 'react-i18next'
import { useLocalStorage } from 'react-use'
import { DownloadSimple } from '@phosphor-icons/react'
import ErrorMessageButton from '../shared/error-message-button'
import BulkAppointmentMapping from './BulkAppointmentMapping'
import { DROPDOWN_LIST } from '../models/Question'
import { AppointmentTypesCustomLabels } from './AppointmentTypesCustomLabels'
import CreateCustomQuestions from './CreateCustomQuestions'
import FacilitiesSelect from './fields/facilities-select'
import ColorPicker from './color-picker'
import StyledSpinner from '../shared/styled-spinner'
import { INBOUND_TYPE, OTHER_TYPE, OUTBOUND_TYPE } from 'components/constants/appointment_types'
import { useHistory } from 'react-router-dom'

const AppointmentTypeForm = ({ match }) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [appointmentType, setAppointmentType] = useState<any>(null)
  const [activeKey, setActiveKey] = useState<string>('0')
  const history = useHistory()
  const { t } = useTranslation()
  const { currentUser } = useContext(CurrentUserContext)
  const { control, getValues, setValue, reset } = useForm({
    mode: 'onChange'
  })
  const [cacheSelectedAppointmentType, setCacheSelectedAppointmentType] = useLocalStorage(
    `DASHBOARD_APPOINTMENT_TYPE_BY_USER_AND_ORG`,
    undefined
  )

  useEffect(() => {
    appointmentTypeService.getAppointmentType(match.params.handle).then(data => {
      data?.questions?.forEach((question, index) => {
        if (question?.answerType === DROPDOWN_LIST) {
          setValue(`${index}`, question?.answerList || [{ label: '', value: '' }])
        }
        if (!question?.position) {
          data.questions[index].position = index + 1
        }
      })
      setAppointmentType({
        ...data,
        bulkAppointmentMappingAttributes:
          Object.keys(data.bulkAppointmentMappingAttributes).length > 0
            ? data.bulkAppointmentMappingAttributes
            : undefined
      })
    })
  }, [])

  const updateAppointmentType = async () => {
    setLoading(true)
    const [data, status] = await appointmentTypeService.updateAppointmentType({
      ...appointmentType,
      questionsAttributes: appointmentType.questionsAttributes?.map((question, index) => {
        if (question.answerType === DROPDOWN_LIST) {
          return {
            ...question,
            answerList: getValues(`${index}`)
          }
        }
        return question
      }),
      bulkAppointmentMappingAttributes:
        appointmentType.bulkAppointmentMappingAttributes?.mapping &&
        Object.keys(appointmentType.bulkAppointmentMappingAttributes.mapping).length > 0
          ? {
              ...appointmentType.bulkAppointmentMappingAttributes,
              mapping: JSON.stringify(appointmentType.bulkAppointmentMappingAttributes.mapping)
            }
          : undefined
    })
    if (status === 200) {
      toast({ info: 'Appointment Type was successfully updated' }, status)
      appointmentTypeService
        .getAppointmentType(match.params.handle)
        .then(data => setAppointmentType(data))
      clearAppointmentTypeCache(appointmentType)
    } else {
      toast(data, status)
    }
    setLoading(false)
  }

  const clearAppointmentTypeCache = appointmentType => {
    if (
      cacheSelectedAppointmentType?.[currentUser.shipperId]?.[currentUser.id] === appointmentType.id
    ) {
      setCacheSelectedAppointmentType(null)
    }
  }

  const onDelete = async () => {
    setLoading(true)
    const [data, status] = await appointmentTypeService.deleteAppointmentType(appointmentType.id)
    if (status == 204) {
      toast({ info: 'Appointment type was successfully deleted.' }, status)
      clearAppointmentTypeCache(appointmentType)
      history.push('/settings/appointment_types')
    } else {
      toast(data, status)
    }
    setLoading(false)
  }

  const updateFormErrors = () => [
    { label: t('Settings.AppointmentTypes.Validations.Name'), status: !!appointmentType.name },
    {
      label: t('Settings.AppointmentTypes.Validations.Color'),
      status: !!appointmentType.color
    },
    {
      label: t('Settings.AppointmentTypes.Validations.Type'),
      status: !!appointmentType.type
    }
  ]

  const [css, theme] = useStyletron()

  if (!appointmentType) return <StyledSpinner />

  return (
    <>
      <Header
        title={t('Settings.AppointmentTypes.Header.Text')}
        rightItems={[
          <Button
            $as="a"
            target="_blank"
            href={`/appointment_types/${appointmentType.id}/download.csv`}
            IconStart={<DownloadSimple />}>
            {t('Settings.AppointmentTypes.DownloadButton.Text')}
          </Button>
        ]}
      />

      <Tabs onChange={params => setActiveKey(params.activeKey.toString())} activeKey={activeKey}>
        <Tab title={t('Settings.AppointmentTypes.Tabs.Details')}>
          <FlexGrid maxWidth="640px">
            <FlexGridItem>
              <FormControl
                htmlFor="appointment_type[name]"
                label={t('Settings.AppointmentTypes.Details.Name')}>
                <Input
                  id="appointment_type[name]"
                  autoComplete="off"
                  name="appointment_type[name]"
                  value={appointmentType.name}
                  onChange={evt =>
                    setAppointmentType({
                      ...appointmentType,
                      name: evt.currentTarget.value
                    })
                  }
                  maxLength={30}
                />
              </FormControl>
            </FlexGridItem>
            <FlexGridItem>
              <FlexGrid flexGridColumnCount={3}>
                <FlexGridItem>
                  <FormControl label={t('Settings.AppointmentTypes.Details.Color')}>
                    <ColorPicker {...{ appointmentType, setAppointmentType }} />
                  </FormControl>
                </FlexGridItem>
                <FlexGridItem>
                  <FormControl label={t('Settings.AppointmentTypes.Details.Public')}>
                    <Checkbox
                      checked={appointmentType?.public}
                      onChange={(e: any) =>
                        setAppointmentType({ ...appointmentType, public: e.target.checked })
                      }
                    />
                  </FormControl>
                </FlexGridItem>
                <FlexGridItem>
                  <FormControl label={t('Settings.AppointmentTypes.Details.Type')}>
                    <FlexGrid>
                      <FlexGridItem>
                        <Checkbox
                          checked={appointmentType?.type === INBOUND_TYPE}
                          onChange={e => {
                            setAppointmentType({
                              ...appointmentType,
                              type: INBOUND_TYPE
                            })
                          }}
                          label={t('Settings.AppointmentTypes.Types.Inbound')}
                        />
                      </FlexGridItem>
                      <FlexGridItem>
                        <Checkbox
                          checked={appointmentType?.type === OUTBOUND_TYPE}
                          onChange={e => {
                            setAppointmentType({
                              ...appointmentType,
                              type: OUTBOUND_TYPE
                            })
                          }}
                          label={t('Settings.AppointmentTypes.Types.Outbound')}
                        />
                      </FlexGridItem>
                      <FlexGridItem>
                        <Checkbox
                          checked={appointmentType?.type === OTHER_TYPE}
                          onChange={e => {
                            setAppointmentType({
                              ...appointmentType,
                              type: OTHER_TYPE
                            })
                          }}
                          label={t('Settings.AppointmentTypes.Types.Other')}
                        />
                      </FlexGridItem>
                    </FlexGrid>
                  </FormControl>
                </FlexGridItem>
              </FlexGrid>
            </FlexGridItem>
            <FlexGridItem>
              <FacilitiesSelect {...{ appointmentType, setAppointmentType }} />
            </FlexGridItem>
          </FlexGrid>
        </Tab>
        <Tab title={t('Settings.AppointmentTypes.Tabs.CustomQuestions')}>
          {loading ? (
            <StyledSpinner />
          ) : (
            <form>
              <CreateCustomQuestions
                appointmentType={appointmentType}
                onAppointmentTypeChange={setAppointmentType}
                control={control}
                setValue={setValue}
                getValues={getValues}
                reset={reset}
              />
            </form>
          )}
        </Tab>
        <Tab title={t('Settings.AppointmentTypes.Tabs.CustomLabels')}>
          {loading ? (
            <StyledSpinner />
          ) : (
            <AppointmentTypesCustomLabels
              appointmentType={appointmentType}
              setAppointmentType={setAppointmentType}
            />
          )}
        </Tab>
        <Tab title={t('Settings.AppointmentTypes.Tabs.UploadMapping')}>
          {loading ? (
            <StyledSpinner />
          ) : (
            <BulkAppointmentMapping
              appointmentType={appointmentType}
              setAppointmentType={setAppointmentType}
            />
          )}
        </Tab>
      </Tabs>
      <div className={css({ display: 'flex', justifyContent: 'space-between', maxWidth: '640px' })}>
        <RemoveButton
          isLoading={loading}
          label={t('Common.Button.Delete.Text')}
          onDelete={onDelete}
        />
        <ErrorMessageButton
          label={t('Common.Button.Update.Text')}
          errors={updateFormErrors()}
          buttonProps={{
            onClick: updateAppointmentType,
            type: 'submit',
            isLoading: loading
          }}
        />
      </div>
    </>
  )
}

export default AppointmentTypeForm
