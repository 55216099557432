import { Appointment } from 'components/appointments/types'
import { Dock } from './Dock'
import { Slot } from './Slot'
import { Facility } from './Facility'

export const TRAILER_EMPTY_STATUS = 'empty'
export const TRAILER_DAMAGED_STATE = 'damaged'
export const TRAILER_NOT_DAMAGED_STATE = 'not-damaged'
export interface Trailer {
  id?: string
  status?: string
  state?: string
  number?: string
  slot?: Slot
  dock?: Dock
  inboundAppointment?: Appointment
  outboundAppointments?: Appointment[]
  facility?: Facility
}

export interface TrailerDetails extends Trailer {
  appointments?: Appointment[]
  fromName?: string
}

export interface TrailerEvent {
  id?: string
  arrivalTime?: Date
  time?: number
  timeLeft?: number
  facility?: Facility
  inboundAppointment?: Appointment
  outboundAppointment?: Appointment
  outboundConfirmationIds?: string[]
  status?: string
  state?: string
  number?: string
  slot?: Slot
  dock?: Dock
  isUnassigned?: boolean
}
