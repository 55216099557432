import { PAGE_SIZE } from 'components/constants/page-size'
import { PaginationResponse } from 'components/types/pagination-response'
import http from 'components/utils/authenticated-fetch'
import { HttpMethods } from '../constants/http-methods'
import authenticatedFetch from 'components/utils/authenticated-fetch'

export const PURCHASE_ORDER_QUERY_PARAM = 'purchase_order_numbers[]='

const DEFAULT_PARAMS = {
  search: '',
  page: 1,
  pageSize: PAGE_SIZE
}
class FacilityService {
  getFacility = (id: string, template?: string): Promise<any> => {
    const query = new URLSearchParams()
    if (template) query.set('template', String(template))

    return http({
      path: `/facilities/${id}.json${query.toString() ? '?' + query.toString() : ''}`
    }).then(([facility]) => facility)
  }

  findFacilitiies = (params = DEFAULT_PARAMS): Promise<PaginationResponse<any>> => {
    const query = new URLSearchParams()
    if (params?.page) query.set('page', String(params.page))
    if (params?.pageSize) query.set('page_size', String(params.pageSize))
    if (params?.search) query.set('name', params.search)

    return http({
      path: `/facilities/paginate.json?${query.toString()}`,
      method: HttpMethods.GET
    }).then(([data]) => data)
  }

  findFacilitiesWithoutPagination = (params): Promise<any> => {
    const query = new URLSearchParams()

    params?.template && query.set('template', params.template)
    params?.filters && query.set('filters', JSON.stringify(params.filters))
    params?.search && query.set('search', params.search)

    return http({
      path: `/facilities.json?${query.toString()}`,
      method: HttpMethods.GET
    }).then(([data]) => data)
  }

  getFacilityDocks = (id: string): Promise<any> =>
    http({
      path: `/facilities/${id}/docks.json`
    }).then(([docks]) => docks)

  getFacilityZones = (id: string): Promise<any> =>
    http({
      path: `/facilities/${id}/zones.json`
    }).then(([zones]) => zones)

  updateFacility = (id, body): Promise<any> =>
    http({
      path: `/facilities/${id}.json`,
      method: HttpMethods.PATCH,
      body
    }).then(([result, status]) => [result, status])

  getFacilities = (body): Promise<any> =>
    http({
      path: `/facilities/get_multiple_facilities_by_ids.json`,
      method: HttpMethods.POST,
      body
    }).then(([result, status]) => [result, status])

  checkOpenSchedulingOnFacilities = (id: string): Promise<any> =>
    http({
      path: `/facilities/${id}/check_open_scheduling_on_facilities.json`
    }).then(facilities => facilities[0])

  dockTypes = (id: string, openScheduling: boolean): Promise<any> =>
    http({
      path: `/facilities/dock_types${
        openScheduling ? '_open_sheduling' : ''
      }.json?appointment_id=${id}`
    }).then(docks => docks)

  availableDays = (
    facilityId: string,
    purchaseOrders: { identifier: string }[],
    handlingMethod?: string
  ): Promise<any> => {
    const params = new URLSearchParams()

    if (purchaseOrders) {
      purchaseOrders?.forEach(po => {
        params.append('purchase_orders[]', po.identifier)
      })
    }

    return http({
      path: `/facilities/${facilityId}/available_days.json?${params.toString()}`
    }).then(days => days)
  }

  searchFacility = async (search?: string) => {
    const [{ hits }, status] = await authenticatedFetch({
      path: `/search.json`,
      method: 'POST',
      body: {
        search: {
          indexName: 'facility',
          params: {
            query: search
          }
        }
      }
    })
    return [200, 304].includes(status) ? hits : []
  }
  userFacilities = async (userId: string) => {
    const [facilities] = await authenticatedFetch({
      path: `/users/${userId}/facilities_list_carrier_user.json`
    })
    return facilities
  }
  openSchedulingFacilities = async (subdomain: string) => {
    const [facilities] = await authenticatedFetch({
      path: `/facilities/open_scheduling_list.json?subdomain=${subdomain}`
    })
    return facilities
  }
  facilityDetails = async (facilityId: number) => {
    const [facility] = await authenticatedFetch({
      path: `/facilities.json/?id[]=${facilityId}`
    })
    return facility
  }

  openSchedulingFacilityDetails = async (facilityId: number) => {
    const [facility] = await authenticatedFetch({
      path: `/facilities/${facilityId}/open_scheduling_facility.json`
    })
    return facility
  }

  availableTimeSlots = async (
    facilityId: string,
    date: string,
    equipmentTypeId: string,
    appointmentTypeId: string,
    handlingMethod: string
  ) => {
    const params = new URLSearchParams()

    const handlingMethodParam =
      handlingMethod === 'drop' ? 'available_timeslots_for_drop.json' : 'available_timeslots.json'

    params.set('date', date as unknown as string)
    params.set('equipment_type_id', equipmentTypeId)
    params.set('appointment_type_id', appointmentTypeId)

    const timeSlots = await authenticatedFetch({
      path: `/facilities/${facilityId}/${handlingMethodParam}?${params.toString()}`
    })
    return timeSlots
  }

  unavailableTimeslotsForDrop = async (facilityIds: string[], date: string) => {
    const params = new URLSearchParams()

    const handlingMethodParam = 'unavailable_timeslots_for_drop.json'

    params.set('date', date as unknown as string)

    facilityIds.forEach(id => {
      params.append(`facility_ids[]`, id)
    })

    const unavailableTimeSlots = await authenticatedFetch({
      path: `/facilities/${handlingMethodParam}?${params.toString()}`
    })

    return unavailableTimeSlots
  }

  getAvailableSlotsAndDocks = (facilityId: string, appointmentType?: string): Promise<any> => {
    const params = new URLSearchParams()

    if (appointmentType) {
      params.append('appointmentType', appointmentType)
    }

    return http({
      path: `/facilities/${facilityId}/empty_slots_and_docks.json?${params.toString()}`
    })
  }

  openSchedulingCheckPurchaseOrderNumber = async (facilityId: string, purchaseOrders: string[]) => {
    const purchaseOrderNumberParams = purchaseOrders
      .map((identifier, index) => {
        return `${index === 0 ? '?' : '&'}${PURCHASE_ORDER_QUERY_PARAM}${identifier}`
      })
      .join('')
    return await authenticatedFetch({
      path: `/facilities/${facilityId}/check_purchase_order_numbers.json${purchaseOrderNumberParams}`,
      method: 'GET'
    })
  }
}

export const facilityService = new FacilityService()
