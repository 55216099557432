import { CustomLabels, CustomLabelsContext } from 'components/contexts/custom-labels-context'
import { RefreshSearchContext } from 'cyber/search/search'
import React, { useContext, useEffect, useState } from 'react'
import { FacilityOption } from '../hooks/use-default-facilities'
import BlackoutModal from './BlackoutModal'
import EditAppointmentModal from './edit-appointment-modal'
import { CustomQuestionsProvider } from 'components/contexts/custom-questions.context'
import EditDropAppointment from './edit-drop-appointment-modal'
import { DROP } from 'components/constants/handling_method'
import CreateDropAppointmentModal from './create-drop-appointment-modal/create-drop-appointment-modal'
import { AppointmentProvider } from 'components/contexts/appointment-context'
import { CarriersProvider } from 'components/contexts/carriers.context'
import FacilityProvider from 'components/contexts/facility-context'

interface ModalsProps {
  selectedEvent: SelectedEvent
  setSelectedEvent: (SelectedEvent) => void
  selectedFacilities?: FacilityOption[]
  mode?: string
}

const Modals = (props: ModalsProps) => {
  const { customLabels, setCustomLabels } = useContext(CustomLabelsContext)
  const { refresh } = useContext(RefreshSearchContext)
  const { selectedEvent, setSelectedEvent, selectedFacilities, mode } = props

  const [initialCustomLabels, setInitialCustomLabels] = useState<CustomLabels>(customLabels)

  useEffect(() => {
    setInitialCustomLabels(customLabels)
  }, [selectedEvent])

  if (!selectedEvent) {
    return <></>
  }

  let Modal = null

  if (mode === 'editAppointment' || (selectedEvent.id && !selectedEvent.blackout)) {
    Modal = EditDropAppointment
  } else if (selectedEvent.blackout) {
    Modal = BlackoutModal
  } else {
    Modal = CreateDropAppointmentModal
  }

  return (
    <CustomQuestionsProvider>
      <CarriersProvider>
        <AppointmentProvider>
          <FacilityProvider>
            <Modal
              selectedEvent={selectedEvent}
              isOpen={!!selectedEvent}
              close={() => {
                setSelectedEvent(null)
                refresh()
                setCustomLabels(initialCustomLabels)
              }}
              selectedFacilities={selectedFacilities}
              unstable_ModalBackdropScroll
            />
          </FacilityProvider>
        </AppointmentProvider>
      </CarriersProvider>
    </CustomQuestionsProvider>
  )
}

export default Modals
