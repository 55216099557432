import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useStyletron } from 'baseui'
import { ButtonProps } from 'baseui/button'
import CancelAppointment from 'components/appointments/modals/cancel-appointment'
import ConfirmModificationRecurringAppointmentModal from 'components/appointments/modals/confirm-modification-recurring-modal'
import Button from 'components/ui/specific/ErrorButton'

const CancelButton = ({
  appointment,
  close,
  appointmentIds,
  label,
  buttonProps
}: {
  appointment: any
  close: Function
  appointmentIds?: string[]
  label?: string
  buttonProps?: ButtonProps
}) => {
  const [openReasonModal, setOpenReasonModal] = useState<boolean>(false)
  const [openRecurringAppointment, setOpenRecurringAppointment] = useState<boolean>(false)
  const { t } = useTranslation()
  const [css, theme] = useStyletron()

  return (
    <div className={css({ marginRight: theme.sizing.scale400 })}>
      <Button
        onClick={() => {
          if (appointment?.recurringAppointmentBlueprintId) {
            setOpenRecurringAppointment(true)
          } else {
            setOpenReasonModal(true)
          }
        }}
        kind="secondary"
        {...buttonProps}>
        {label ? label : t('Common.Button.CancelAppointment.Text')}
      </Button>

      {appointment?.recurringAppointmentBlueprintId && !openReasonModal ? (
        <ConfirmModificationRecurringAppointmentModal
          isOpen={openRecurringAppointment}
          close={() => {
            setOpenRecurringAppointment(false)
          }}
          closeParent={close}
          appointment={appointment}
          openReasonModal={() => setOpenReasonModal(true)}
          type="DELETE"
        />
      ) : (
        <CancelAppointment
          isOpen={openReasonModal}
          close={deleted => {
            setOpenReasonModal(false)
            close(deleted)
          }}
          appointment={appointment}
          appointmentIds={appointmentIds}
          openScheduling
        />
      )}
    </div>
  )
}

export default CancelButton
