import { Appointment } from 'components/appointments/types'
import { StatusCodes } from 'components/constants/http-status-codes'
import { PAGE_SIZE } from 'components/constants/page-size'
import { CurrentUserContext } from 'components/homepage/current-user-context'
import { useHttp } from 'components/hooks/use-http'
import { usePagination } from 'components/hooks/use-pagination'
import { YARD_JOCKEY_AUDIENCE } from 'components/models/Role'
import { Trailer, TrailerDetails, TrailerEvent } from 'components/models/Trailer'
import trailersReducer, {
  SET_FACILITY,
  SET_LOADING,
  SET_TASK,
  SET_TASK_MODAL,
  SET_TRAILER_DETAILS,
  SET_TRAILERS
} from 'components/reducers/trailers.reducer'
import { taskService } from 'components/services/task.service'
import { trailerService } from 'components/services/trailer.service'
import { userService } from 'components/services/user.service'
import { PaginationResponse } from 'components/types/pagination-response'
import React, { createContext, useContext, useEffect, useReducer } from 'react'
import { useDebounce } from 'react-use'
import { FIXED_DOCK_DOORS_COLUMN } from './yard-schedule.context'
import { fancyToast } from 'components/utils'
import { slotService } from 'components/services'
import { useTranslation } from 'react-i18next'
import { Task } from 'components/models/Task'
import TimeFormatter from 'components/utils/time-formatter'

export interface TrailerContextProps {
  trailers: {
    trailers: Trailer[]
    loading: boolean
    total: number
  }
  taskModal: {
    task: Task
    trailer: {
      loading: boolean
      trailerInfo: TrailerDetails
    }
    isOpen: boolean
    forDriver: boolean
    trailerNumber: string
  }
  search: string
  selectedFacility: any
  pagination?: {
    page: number
    pageSize: number
  }
}

const initialState: TrailerContextProps = {
  trailers: {
    trailers: [],
    loading: false,
    total: 0
  },
  taskModal: {
    task: null,
    trailer: {
      loading: false,
      trailerInfo: null
    },
    isOpen: false,
    forDriver: false,
    trailerNumber: null
  },
  selectedFacility: [],
  search: null,
  pagination: {
    page: 1,
    pageSize: PAGE_SIZE
  }
}

interface TrailerContextType {
  state: TrailerContextProps
  actions: {
    setPage: (number: number) => void
    setSelectedFacility: (facility: any) => void
    openMoveTrailer: (trailer: TrailerEvent) => void
    createTask: () => void
    setTask: (task: Task) => void
    closeTaskModal: () => void
  }
}

export const TrailerContext = createContext<TrailerContextType | undefined>(undefined)

export const TrailerProvider = ({ children }) => {
  const { t } = useTranslation()
  const { currentUser } = useContext(CurrentUserContext)
  const [state, dispatch] = useReducer(trailersReducer, initialState)
  const { page, pageSize, setPage } = usePagination()

  const [findTrailerPaginted, { data, loading }] = useHttp<PaginationResponse<Trailer>>(
    trailerService.getTrailerPaginated
  )

  const [loadUsers, { data: users, loading: loadingUsers }] = useHttp<any>(
    userService.getUsersByRoleAudience
  )

  const loadTrailer = () => {
    if (state.selectedFacility.length > 0) {
      findTrailerPaginted({
        page,
        pageSize,
        search: state.search,
        facilityId: state.selectedFacility.map(facility => facility?.id),
        active: true
      })
    } else {
      dispatch({
        type: SET_TRAILERS,
        payload: {
          ...initialState.trailers
        }
      })
    }
  }

  const loadTrailerDetails = async (trailerId: string) => {
    setTrailerLoading(true)
    try {
      if (trailerId) {
        const [trailer, status] = await trailerService.getTrailerDetailsById(trailerId)
        if (status == StatusCodes.OK) {
          dispatch({
            type: SET_TRAILER_DETAILS,
            payload: {
              trailerInfo: {
                ...trailer
              }
            }
          })
          setFrom(trailer)
        }
      }
    } catch (error) {
      console.error(error)
    } finally {
      setTrailerLoading(false)
    }
  }

  useDebounce(
    () => {
      loadTrailer()
    },
    200,
    [page, pageSize, state.search, state.selectedFacility]
  )

  useDebounce(() => loadUsers([YARD_JOCKEY_AUDIENCE]), 200, [currentUser?.shipperId])

  const formatTrailersEvents = (trailers: Trailer[]): TrailerEvent[] => {
    return trailers?.map(trailer => ({
      id: trailer.id,
      trailerNumber: trailer.number,
      arrivalTime: trailer.inboundAppointment?.arrivalTime,
      time: new TimeFormatter('shortTime').getDaysElapsed(trailer.inboundAppointment?.arrivalTime),
      timeLeft: 2,
      facility: trailer.facility,
      inboundAppointment: trailer.inboundAppointment,
      outboundAppointment: trailer.outboundAppointments && trailer.outboundAppointments[0],
      outboundConfirmationIds: getConfirmationIds(trailer.outboundAppointments),
      status: trailer.status,
      state: trailer.state,
      number: trailer.number,
      slot: trailer.slot,
      dock: trailer.dock,
      isUnassigned: !(trailer.dock?.name || trailer.slot?.name)
    }))
  }

  useEffect(() => {
    dispatch({
      type: SET_TRAILERS,
      payload: {
        ...state.trailers,
        trailers: formatTrailersEvents(data?.results) || [],
        total: data?.total || 0
      }
    })
  }, [data])

  useEffect(() => {
    dispatch({ type: SET_LOADING, payload: loading || loadingUsers })
  }, [loading])

  useDebounce(() => loadTrailerDetails(state.taskModal.task?.trailerId), 200, [
    state.taskModal.task?.trailerId
  ])

  const setSelectedFacility = (facility: any) => {
    dispatch({ type: SET_FACILITY, payload: facility })
  }

  const getConfirmationIds = (appointments: Appointment[]): string[] => {
    return appointments.map(p => p.confirmationId) || []
  }

  const openMoveTrailer = (trailer: TrailerEvent) => {
    dispatch({
      type: SET_TASK_MODAL,
      payload: {
        ...initialState.taskModal,
        isOpen: true,
        forDriver: trailer.isUnassigned,
        trailerNumber: trailer?.number,
        task: {
          trailerId: trailer?.id,
          facilityId: trailer.inboundAppointment?.facilityId
        }
      }
    })
  }

  const setTask = (task: Task) => {
    dispatch({ type: SET_TASK, payload: task })
  }

  const setTrailerLoading = (value: boolean) => {
    dispatch({
      type: SET_TRAILER_DETAILS,
      payload: {
        loading: value
      }
    })
  }

  const setFrom = (trailer: TrailerDetails) => {
    if (trailer?.dock?.id) {
      setTask({
        ...state.taskModal.task,
        dock: trailer.dock
      })
    } else if (trailer?.slot?.id) {
      setTask({
        ...state.taskModal.task,
        fromSlot: trailer.slot,
        fromSlotId: trailer.slot.id
      })
    } else {
      setTask({
        ...state.taskModal.task,
        fromSlot: null,
        fromSlotId: null,
        dock: null,
        dockId: null
      })
    }
  }

  const createTask = async () => {
    if (state.taskModal?.forDriver) {
      const [data, status] = await slotService.assignTrailerToSlotOrDock(
        state.taskModal.task?.trailerId,
        state.taskModal.task?.dockId,
        state.taskModal.task?.toSlotId,
        state.taskModal.task?.facilityId,
        state.taskModal.task?.userId
      )

      if (status === StatusCodes.OK) {
        loadTrailer()
        fancyToast({ info: t('YardSchedule.DropModal.SuccessAssignment.Text') }, StatusCodes.OK)
      } else {
        fancyToast(
          { info: t('YardSchedule.DropModal.ErrorAssignment.Text') },
          StatusCodes.INTERNAL_SERVER_ERROR
        )
      }

      dispatch({ type: SET_TASK_MODAL, payload: { ...initialState.taskModal } })
    } else {
      const json = await taskService.createTask(state.taskModal.task)

      if (json) {
        dispatch({ type: SET_TASK_MODAL, payload: { ...initialState.taskModal } })
      }
    }
  }

  const closeTaskModal = () => {
    dispatch({
      type: SET_TASK_MODAL,
      payload: {
        ...initialState.taskModal
      }
    })
  }

  const actions = {
    setPage,
    setSelectedFacility,
    openMoveTrailer,
    createTask,
    setTask,
    closeTaskModal
  }

  return (
    <TrailerContext.Provider
      value={{ state: { ...state, pagination: { page: page, pageSize: pageSize } }, actions }}>
      {children}
    </TrailerContext.Provider>
  )
}

export const useTrailerContext = (): TrailerContextType => {
  const context = useContext(TrailerContext)
  if (context === undefined) {
    throw new Error('useTrailerContext must be used within an TrailerProvider')
  }
  return context
}
