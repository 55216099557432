import React, { useState, useEffect, useContext } from 'react'
import Header from 'components/ui/generic/Header'
import SearchBar from './search-bar'
import { useTranslation } from 'react-i18next'
import { PurchaseOrderLabelsContext } from 'components/pages/CreateAppointmentForExternalUsers'
import authenticatedFetch from 'components/utils/authenticated-fetch'
import { DEFAULT_SHIPPER_SUBDOMAIN } from '../../constants/default-shipper-subdomain'
import { Card, StyledBody } from 'baseui/card'
import { Block } from 'baseui/block'

import { AppointmentCard } from 'components/components/AppointmentCard'
import Divider from '../../ui/generic/Divider'
import { Link } from 'react-router-dom'
import { Paragraph1, Paragraph2, Paragraph4, ParagraphSmall } from 'baseui/typography'
import { appointmentNotFoundProps } from './UpdateAppointmentExternalSearch.styled'
import { Search } from 'baseui/icon'

const UpdateAppointmentExternalSearch = () => {
  const subdomain = window.location.hostname.split('.')[0]
  const [appointments, setAppointments] = useState<any>({})
  const [mainAppointment, setMainAppointment] = useState(null)
  const [currentPage, setCurrentPage] = useState(1)
  const purchaseOrderLabelsContext = useContext(PurchaseOrderLabelsContext)
  const { purchaseOrderLabels } = purchaseOrderLabelsContext

  const { t } = useTranslation()
  const customPOLabel = purchaseOrderLabels?.purchase_order_identifiers
    ? purchaseOrderLabels?.purchase_order_identifiers
    : t('Scheduler.Appointments.SearchBox.Header.Text')

  useEffect(() => {
    authenticatedFetch({
      path: `/facilities/open_scheduling_list.json?subdomain=${
        subdomain === 'localhost' ? DEFAULT_SHIPPER_SUBDOMAIN : subdomain
      }`
    })
      .then(([facilityLabels, _status]) => {
        if (facilityLabels.length >= 1) {
          setMainAppointment(facilityLabels[0])
        }
      })
      .catch(console.log)
  }, [])

  return (
    <>
      <PurchaseOrderLabelsContext.Provider
        value={{
          purchaseOrderLabels:
            mainAppointment &&
            mainAppointment.appointmentPreference &&
            mainAppointment.appointmentPreference.purchase_order_field_names
        }}>
        <Header title={t('HeaderNavigation.NavBarLinks.UpdateAppointment.Text')} />
        <Block maxWidth={['100%', '100%', '80%', '70%']}>
          <Card
            overrides={{
              Root: {
                style: {
                  backgroundColor: 'white'
                }
              }
            }}>
            <StyledBody>
              <Block maxWidth={['100%', '100%', '70%', '75%']}>
                <Paragraph2>{t('Scheduler.Appointments.HeaderDrop.Title.Text')}</Paragraph2>
                <Paragraph4>
                  <small>{t('Scheduler.Appointments.HeaderDrop.Subtitle.Text')}</small>
                </Paragraph4>
                <SearchBar
                  setAppointments={setAppointments}
                  setCurrentPage={setCurrentPage}
                  currentPage={currentPage}
                />
              </Block>
            </StyledBody>
            <Divider />
            <Block
              marginTop="scale800"
              maxWidth={['100%', '100%', appointments.total > 0 ? '75%' : '100%']}>
              {appointments.total > 0 ? (
                appointments?.results?.map((appointment: any) => (
                  <Link
                    style={{
                      textDecoration: 'none'
                    }}
                    to={`/appointments/${appointment.id}/reschedule`}>
                    <AppointmentCard appointment={appointment} />
                  </Link>
                ))
              ) : (
                <Block
                  display="flex"
                  justifyContent="center"
                  alignItems="start"
                  flexDirection={'column'}>
                  <Paragraph1 margin={'0px'}>
                    <Search size={42} />
                  </Paragraph1>
                  <Paragraph1 margin={'0px'}>
                    <strong>{t('Scheduler.Appointments.Table.NoAppointmetnsFound.Text')}</strong>
                  </Paragraph1>
                  <ParagraphSmall {...appointmentNotFoundProps}>
                    {t('Scheduler.Appointments.Table.PleaseTryAgain.Text', {
                      label: customPOLabel
                    })}
                  </ParagraphSmall>
                </Block>
              )}
            </Block>
          </Card>
        </Block>
      </PurchaseOrderLabelsContext.Provider>
    </>
  )
}

export default UpdateAppointmentExternalSearch
