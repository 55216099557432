import styled from 'styled-components'
import { primitives } from '../../homepage/custom-theme'

export const AppointmentInfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 185px;
`
export const AppointmentInfoLabel = styled.span`
  color: ${primitives.text};
  font-family: Inter;
  font-size: 9px;
  font-style: normal;
  font-weight: 500;
  line-height: 10px;
`
export const AppointmentInfoLabelText = styled.span`
  color: ${primitives.text};
  text-align: right;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 10px;
`

export const ModalContentTopContainer = styled.div`
  padding: 12px;
  gap: 4px;
  border-radius: 16px;
  border: 1px solid rgba(117, 158, 255, 0.2);
  background: rgba(219, 230, 255, 0.2);
  margin-top: '24px';
`
export const ModalContentTopContainerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
export const ModalContentCustomerName = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ModalContentPO = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  margin-top: 8px;
`
export const CustomerNameTitle = styled.span`
  color: ${primitives.primary};
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 12px;
  margin-left: 5px;
`
export const PurchaseOrderText = styled.span`
  color: ${primitives.primary};
  text-align: right;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 10px;
`
export const DateText = styled.span`
  color: ${primitives.text};
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 10px;
`

export const trailerStatusTagProps = {
  overrides: {
    Root: {
      style: {
        backgroundColor: '#DBE6FF',
        textTransform: 'capitalize'
      }
    }
  }
}
