import { BlockProps } from 'baseui/block'
import { ModalProps } from 'baseui/modal'
import { TabProps } from 'baseui/tabs'
import styled from 'styled-components'

export const TextLine = styled.span`
  display: block;
`
export const TextContainer = styled.div`
  display: block;
  margin-bottom: 10px;
`

export const IconEquipmentTypeDiv = styled.span`
  margin-right: 10px;
`
export const TagDiv = styled.div`
  margin-bottom: 5px;
`

export const ModalDropProps: ModalProps = {
  overrides: {
    Dialog: { style: { width: '480px', maxWidth: '480px' } },
    Close: {
      style: ({ $theme }) => ({
        right: $theme.sizing.scale800,
        top: $theme.sizing.scale800
      })
    }
  }
}

export const itemProps: BlockProps = {
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  marginBottom: '10px'
}

export const FlexGridHeaderProps: BlockProps = {
  overrides: {
    Block: {
      style: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        alignItems: 'center'
      }
    }
  }
}

export const FlexGridHeaderSubtitleProps: BlockProps = {
  overrides: {
    Block: {
      style: ({ $theme }) => ({
        width: `calc((200% - ${$theme.sizing.scale900}) / 3)`
      })
    }
  }
}

export const FlexGridPanelContentProps: BlockProps = {
  overrides: {
    Block: {
      style: {
        display: 'flex',
        itemAlign: 'center',
        maxWidth: '400px'
      }
    }
  }
}

export const FlexGridFooterProps: BlockProps = {
  overrides: {
    Block: {
      style: {
        display: 'flex',
        columnGap: '20px',
        width: '100%',
        justifyContent: 'end',
        maxWidth: '100%'
      }
    }
  }
}

export const TabPanelProps: TabProps = {
  overrides: {
    Tab: {
      style: {
        paddingTop: '0px',
        paddingBottom: '0px'
      }
    }
  }
}
