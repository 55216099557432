import React from 'react'
import { ArrowLineDown, ArrowLineUp, Truck } from '@phosphor-icons/react'
import { Task, TaskEvent } from 'components/models/Task'
import { TaskEventStyled } from './TaskEvent.styled'
import EventIcon from './EventIcon'
import { INBOUND_TYPE, OUTBOUND_TYPE } from 'components/constants/appointment_types'

const ICONS_SIZE = 10
const TaskEventTile = ({ event }: { event: TaskEvent }) => {
  const {
    trailer: { appointments }
  } = event
  const appointment = appointments[0]

  return (
    <TaskEventStyled status={event.status} className="task-event">
      <div className="task-event-container">
        <div className="task-event-header">
          <div className="task-event-scheduler">
            <EventIcon
              event={{
                extendedProps: {
                  equipmentTypeId: appointment?.equipmentTypeId
                }
              }}
            />
            <div className="task-event-title">{appointment?.schedulerName}</div>
          </div>
          <span className="task-event-info">
            <span className="task-event-type">
              {appointment?.appointmentType === INBOUND_TYPE ? (
                <ArrowLineDown size={ICONS_SIZE} />
              ) : appointment?.appointmentType === OUTBOUND_TYPE ? (
                <ArrowLineUp size={ICONS_SIZE} />
              ) : (
                appointment?.appointmentType
              )}
            </span>
            <span className="task-event-id">{(appointment as any)?.purchaseOrderIdentifiers}</span>
          </span>
        </div>
      </div>
    </TaskEventStyled>
  )
}

export default TaskEventTile
