import React from 'react'
import {
  ColumnsDirective,
  ColumnDirective,
  CardClickEventArgs,
  ActionEventArgs
} from '@syncfusion/ej2-react-kanban'

import '@syncfusion/ej2-base/styles/bootstrap5.css'
import '@syncfusion/ej2-layouts/styles/bootstrap5.css'
import '@syncfusion/ej2-react-kanban/styles/bootstrap5.css'
import { useTasksContext } from 'components/contexts/tasks.context'
import TaskEventTile from 'components/ui/specific/TaskEvent'
import { ConfirmationModal } from 'components/components/ConfirmationModal'
import ReactKanban from './kanban/ReactKanban'
import { TASK_COMPLETED, TASK_STARTED, TASK_UNASSIGNED, TaskEvent } from 'components/models/Task'
import { useTranslation } from 'react-i18next'

const TOTAL_POSITIONS = 25

const TasksBoardKanban = () => {
  const { state, actions } = useTasksContext()
  const {
    tasks: {
      events,
      confirmationModal: { open: confirmationModalOpen, loading: confirmLoading }
    },
    users
  } = state

  const { validateDrop, cancelConfirmationModal, confirmConfirmationModal, onEventClick } = actions

  const { t } = useTranslation()

  const cardTemplate = pr => {
    if (pr.isTemplate) {
      return <></>
    }
    return <TaskEventTile event={pr} />
  }

  const getColumnsName = () => users?.filter(item => item.label && item.value)
  const columnsName = users ? getColumnsName() : []

  const onMoveCard = (args: ActionEventArgs) => {
    if (args.requestType === 'cardChange') {
      if (
        [TASK_STARTED, TASK_COMPLETED].includes(args.changedRecords[0]?.status) ||
        validateAvailableRank(args.changedRecords[0])
      ) {
        args.cancel = true
      } else {
        validateDrop(args.changedRecords[0])
      }
    }
  }

  const validateAvailableRank = (event: TaskEvent): boolean => {
    return events.some(e => e.assigned === event.assigned && e.rank === event.rank)
  }

  const onClick = (args: CardClickEventArgs) => {
    onEventClick(args.data)
  }

  const fillRanks = () => {
    return Array.from({ length: TOTAL_POSITIONS }, (_, index) => ({
      rank: (index + 1).toString(),
      isTemplate: true
    }))
  }

  return (
    <>
      <ReactKanban
        data-testid="task-kanban-container"
        id="kanban"
        keyField="assigned"
        delayUpdate
        showEmptyColumn
        dataSource={[...events, ...fillRanks()]}
        width={'auto'}
        height={'60vh'}
        dataBound={dataBound}
        actionBegin={$event => {
          onMoveCard($event)
        }}
        swimlaneSettings={{
          keyField: 'rank',
          showEmptyRow: true,
          allowDragAndDrop: true,
          showItemCount: false
        }}
        cardClick={$event => {
          onClick($event)
        }}
        cardSettings={{
          contentField: 'id',
          headerField: 'id',
          template: cardTemplate
        }}>
        <ColumnsDirective>
          <ColumnDirective
            headerText={t('YardTask.Kanban.Columns.Unassigned')}
            keyField={TASK_UNASSIGNED}
            showItemCount={false}
          />
          {columnsName.map(header => (
            <ColumnDirective
              headerText={header.label}
              keyField={header.value}
              allowToggle
              isExpanded={true}
              showItemCount={false}
              key={header.value}
            />
          ))}
        </ColumnsDirective>
        <ConfirmationModal
          isOpen={confirmationModalOpen}
          loading={confirmLoading}
          title={t('YardTask.Kanban.ConfirmModal.AssignTask.Text')}
          cancelAction={cancelConfirmationModal}
          confirmAction={confirmConfirmationModal}
        />
      </ReactKanban>
    </>
  )
}

export default TasksBoardKanban

function dataBound() {
  if (document.getElementsByTagName('colgroup')[0]?.childNodes?.length > 0) {
    for (var i = 0; i < document.getElementsByTagName('colgroup')[0].childNodes.length; i++) {
      const child = document.getElementsByTagName('colgroup')[0].childNodes[i] as HTMLElement
      child.classList.add('ColHeader')
    }
  }
}
