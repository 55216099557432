import React, { createContext, useContext, useEffect, useCallback } from 'react'

import facilityReducer, {
  SET_FACILITY,
  SET_FACILITY_OPTIONS,
  SET_FACILITY_RESPONSE_DONE
} from 'components/reducers/facility.reducer'
import { Facility } from 'components/models/Facility'
import { CurrentUserContext } from 'components/homepage/current-user-context'
import { useAppointmentContext } from './appointment-context'
import { facilityService, shipperService } from 'components/services'
import { CARRIER } from 'components/constants/user-types'
import { DEFAULT_SHIPPER_SUBDOMAIN } from 'components/constants/default-shipper-subdomain'
import { useSafeDispatch } from 'components/hooks/use-safe-dispatch'

interface FacilityState {
  facility: Facility
  facilityOptions: any[]
  facilityRequestDone: boolean
}

interface FacilityContextType {
  state: FacilityState
  actions: {
    setFacility: (facility: any) => void
    setFacilityOptions: (options: any) => void
  }
}

export const FacilityState = {
  facility: {},
  facilityOptions: [],
  facilityRequestDone: false
}

export const FacilityContext = createContext<FacilityContextType | undefined>(undefined)

const FacilityProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(facilityReducer, FacilityState)
  const safeDispatch = useSafeDispatch(dispatch)
  const { currentUser } = useContext(CurrentUserContext)
  const {
    state: { appointmentId, appointmentEdit, confirmationIds, appointments },
    actions: { setAppointment }
  } = useAppointmentContext()

  const subdomain = window.location.hostname.split('.')[0]
  const envSubdomain = subdomain === 'localhost' ? DEFAULT_SHIPPER_SUBDOMAIN : subdomain

  const fetchShipperOrUserFacilities = useCallback(async () => {
    let response
    try {
      if (!currentUser) {
        response = await facilityService.openSchedulingFacilities(envSubdomain)
      } else {
        response =
          currentUser?.userType === CARRIER
            ? await facilityService.userFacilities(currentUser?.id)
            : (await shipperService.getFacilityList(currentUser?.shipperId)) || null
      }

      safeDispatch({ type: SET_FACILITY_OPTIONS, payload: response })
      safeDispatch({ type: SET_FACILITY_RESPONSE_DONE, payload: true })
      if (appointmentEdit?.facilityId) {
        const selectedFacility =
          response.length > 0 && response.filter(item => item.id == appointmentEdit?.facilityId)
        setFacility(selectedFacility[0])
      } else {
        setFacility(response[0])
      }
    } catch (error) {
      throw new Error('Error fetching shipper or user facilities')
    }
  }, [
    currentUser?.id,
    currentUser?.shipperId,
    currentUser?.userType,
    appointmentId,
    appointmentEdit?.facilityId
  ])

  const fetchFacilityDetails = async (facilityId: number) => {
    try {
      const response = await facilityService.facilityDetails(facilityId)
      return response[0]
    } catch (error) {
      throw new Error('Error fetching facility details')
    }
  }

  useEffect(() => {
    if (!appointments.inbound.facilityId && !appointments.outbound.facilityId) {
      setAppointment({ facilityId: state.facility?.id })
    }
  }, [appointments.inbound?.facilityId, appointments.outbound?.facilityId])

  const setFacility = async (facility: any) => {
    const facilityDetails = currentUser
      ? await fetchFacilityDetails(facility?.id)
      : await facilityService.openSchedulingFacilityDetails(facility?.id)
    safeDispatch({ type: SET_FACILITY, payload: facilityDetails })
    setAppointment({ facilityId: facility?.id })
  }

  const setFacilityOptions = (options: any) => {
    safeDispatch({ type: SET_FACILITY_OPTIONS, payload: options })
  }

  const actions = {
    setFacility,
    setFacilityOptions
  }

  useEffect(() => {
    fetchShipperOrUserFacilities()
  }, [fetchShipperOrUserFacilities])

  return <FacilityContext.Provider value={{ state, actions }}>{children}</FacilityContext.Provider>
}

export default FacilityProvider

export const useFacilityContext = () => {
  const context = React.useContext(FacilityContext)
  if (context === undefined) {
    throw new Error('useFacilityContext must be used within a FacilityProvider')
  }
  return context
}
