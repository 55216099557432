import http from 'components/utils/authenticated-fetch'

class UserService {
  getUsersByRoleAudience = async (audience: string[], name?: string): Promise<[any, number]> => {
    const params = new URLSearchParams()

    if (name && name !== '') {
      params.append('name', name)
    }

    audience.forEach(audience => {
      params.append(`audience[]`, audience)
    })

    const users = await http({
      path: `/users/search_users_by_role.json?${params.toString()}`
    })

    return users
  }
}

export const userService = new UserService()
