import React, { useEffect, useState } from 'react'
import { facilityService } from '../../services'
import { StatusCodes } from '../../constants/http-status-codes'
import { useTranslation } from 'react-i18next'
import TimeFormatter from '../../utils/time-formatter'
import { Warning } from '@phosphor-icons/react'
import { primitives } from '../../homepage/custom-theme'
import { LabelSmall, ParagraphXSmall } from 'baseui/typography'
import { Block } from 'baseui/block'
import { Accordion, Panel } from 'baseui/accordion'
import { AccordionOverrides, TypographyStyle } from './YardCapacityAlert.styled'

const YardCapacityAlert = ({ facilityIds, date }) => {
  const [overCapacitySlots, setOverCapacitySlots] = useState({})
  const { t } = useTranslation()

  useEffect(() => {
    let isMounted = true

    if (facilityIds.length > 0 && date) {
      facilityService
        .unavailableTimeslotsForDrop(facilityIds, new Date(date).toISOString())
        .then(([results, status]) => {
          if (isMounted && status === StatusCodes.OK) {
            setOverCapacitySlots(results)
          }
        })
        .catch(() => {
          if (isMounted) {
            console.error('Something went wrong loading available slots for drop')
          }
        })
    }

    return () => {
      isMounted = false
    }
  }, [date, JSON.stringify(facilityIds)])

  return (
    Object.keys(overCapacitySlots).length > 0 && (
      <Accordion overrides={AccordionOverrides}>
        <Panel
          title={
            <Block alignItems="center" display="flex" gridGap="scale400">
              <Warning color={primitives.warning} size={16} />
              <LabelSmall display="inline" color={primitives.warning} $style={TypographyStyle}>
                {t('YardSchedule.Errors.OverCapacityWarning.Text')}
              </LabelSmall>
            </Block>
          }>
          {Object.keys(overCapacitySlots).map(facilityName => (
            <Block key={facilityName}>
              <ParagraphXSmall display="inline" $style={TypographyStyle}>
                <strong>{facilityName}</strong>{' '}
                {overCapacitySlots[facilityName]
                  .map(slot => new TimeFormatter('localCalendarTime12h').format(slot.timeSlot))
                  .join(', ')}
              </ParagraphXSmall>
            </Block>
          ))}
        </Panel>
      </Accordion>
    )
  )
}

export default YardCapacityAlert
