import React, { useState, useEffect, useCallback } from 'react'
import { Block } from 'baseui/block'
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid'
import { fancyToast } from 'components/utils'
import { facilityService } from 'components/services'
import Select from '../../ui/generic/Select'
import { INBOUND, OUTBOUND, useAppointmentContext } from 'components/contexts/appointment-context'
import { useFacilityContext } from 'components/contexts/facility-context'
import moment from 'moment-timezone'
import { useTranslation } from 'react-i18next'
import DatePicker from 'components/ui/generic/DatePicker'
import FormControl from 'components/ui/generic/FormControl'

export function DateTime() {
  const { state, actions } = useAppointmentContext()
  const { appointments, handlingMethod, appointmentDirections } = state
  const { t } = useTranslation()

  const { setAppointment, setIsTimeSet } = actions
  const {
    state: { facility }
  } = useFacilityContext()

  const [availableDates, setAvailableDates] = useState([])
  const [availableTimes, setAvailableTimes] = useState([])

  const handleDateChange = date => {
    const newDate = new Date(date)
    appointmentDirections.forEach(direction => {
      setAppointment({ arrivalTime: newDate }, direction)
    })
  }

  const timezone = 'America/New_York'
  const handleTimeChange = time => {
    const timeFormat = 'hh:mm A'
    const newTime = moment(time, timeFormat).tz(timezone)

    appointmentDirections.forEach(direction => {
      const currentDate = moment(appointments[direction].arrivalTime).tz(timezone)
      currentDate.hours(newTime.hours())
      currentDate.minutes(newTime.minutes())
      setAppointment({ arrivalTime: currentDate.toDate() }, direction)
    })
    setIsTimeSet(true)
  }

  const fetchAvailableDates = useCallback(async () => {
    if (handlingMethod && facility.id) {
      const activeDirections = appointmentDirections.filter(
        direction => appointments[direction].purchaseOrdersAttributes.length > 0
      )

      if (activeDirections.length) {
        const [{ fullTruckloadDays }, status] = await facilityService.availableDays(
          facility.id,
          appointments[activeDirections[0]].purchaseOrdersAttributes,
          handlingMethod
        )
        if (status !== 200) {
          console.error('Something went wrong with the available dates.')
          return
        } else {
          setAvailableDates(fullTruckloadDays)
        }
      }
    }
  }, [handlingMethod, facility.id, appointmentDirections, appointments])

  const fetchAvailableTimes = useCallback(
    async date => {
      const activeDirections = appointmentDirections.filter(
        direction =>
          appointments[direction].equipmentTypeId && appointments[direction].appointmentTypeId
      )

      if (activeDirections.length) {
        const formattedArrivalTime = date.toISOString()

        const [timeSlotResults, status] = await facilityService.availableTimeSlots(
          facility.id,
          formattedArrivalTime,
          appointments[activeDirections[0]].equipmentTypeId,
          appointments[activeDirections[0]].appointmentTypeId,
          handlingMethod
        )

        const allAvailableTimeSlots = Array.isArray(timeSlotResults)
          ? timeSlotResults.map(timeSlots => {
              if (status !== 200) {
                fancyToast(timeSlots, status)
                return []
              }
              return timeSlots
            })
          : []

        setAvailableTimes(
          allAvailableTimeSlots.map(time => ({
            id: new Date(time.timeSlot).toLocaleTimeString([], {
              hour: '2-digit',
              minute: '2-digit',
              hour12: true
            }),
            label: new Date(time.timeSlot).toLocaleTimeString([], {
              hour: '2-digit',
              minute: '2-digit',
              hour12: true
            })
            // disabled: !time.available // TODO: if time not available add a label that shows overload capacity instead of disabled
          }))
        )
      }
    },
    [handlingMethod, facility.id, appointmentDirections, appointments]
  )

  const isDateAvailable = date => {
    return availableDates.some(availableDate => {
      const available = new Date(availableDate)
      return (
        date.getFullYear() === available.getFullYear() &&
        date.getMonth() === available.getMonth() &&
        date.getDate() === available.getDate()
      )
    })
  }

  useEffect(() => {
    fetchAvailableDates()
  }, [fetchAvailableDates])

  useEffect(() => {
    if (facility?.id) {
      fetchAvailableTimes(getCurrentArrivalTime())
    }
  }, [appointments[OUTBOUND].arrivalTime, appointments[INBOUND].arrivalTime, facility?.id])

  const getCurrentArrivalTime = () => {
    if (appointmentDirections.includes(INBOUND) && appointmentDirections.includes(OUTBOUND)) {
      return appointments[INBOUND].arrivalTime || appointments[OUTBOUND].arrivalTime
    } else if (appointmentDirections.includes(INBOUND)) {
      return appointments[INBOUND].arrivalTime
    } else if (appointmentDirections.includes(OUTBOUND)) {
      return appointments[OUTBOUND].arrivalTime
    }
    return null
  }

  return (
    <Block
      display={
        appointments[INBOUND].purchaseOrdersAttributes.length === 0 &&
        appointments[OUTBOUND].purchaseOrdersAttributes.length === 0
          ? 'none'
          : 'block'
      }
      flexDirection="column">
      <FlexGrid flexGridColumnCount={[1, 1, 2]} flexGridColumnGap="scale800" width="100%">
        <FlexGridItem>
          <FormControl>
            <DatePicker
              value={getCurrentArrivalTime() ? new Date(getCurrentArrivalTime()) : null}
              placeholder=""
              onChange={({ date }) => handleDateChange(date)}
              label={t('Appointments.CreateAppointmentModal.Fields.ArrivalDate.Label.Text')}
              formatString="MM/dd/yyyy"
              filterDate={isDateAvailable}
              aria-label="appointment-available-dates"
              disabled={
                appointments[INBOUND].purchaseOrdersAttributes.length === 0 &&
                appointments[OUTBOUND].purchaseOrdersAttributes.length === 0
              }
            />
          </FormControl>
        </FlexGridItem>
        <FlexGridItem>
          <FormControl>
            <Select
              label={t('Appointments.CreateAppointmentModal.Fields.ArrivalTime.Label.Text')}
              options={availableTimes}
              aria-label="available-time-slots"
              onChange={({ option }) => handleTimeChange(option.id)}
              clearable={false}
              value={
                getCurrentArrivalTime()
                  ? [
                      {
                        id: new Date(getCurrentArrivalTime()).toLocaleTimeString([], {
                          hour: '2-digit',
                          minute: '2-digit'
                        })
                      }
                    ]
                  : null
              }
              placeholder="Select time"
              disabled={
                !getCurrentArrivalTime() ||
                (appointments[INBOUND].purchaseOrdersAttributes.length === 0 &&
                  appointments[OUTBOUND].purchaseOrdersAttributes.length === 0)
              }
            />
          </FormControl>
        </FlexGridItem>
      </FlexGrid>
    </Block>
  )
}
