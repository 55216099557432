import React, { useEffect, useState } from 'react'
import Select from 'components/ui/generic/Select'
import { useDebounce } from 'react-use'
import { facilityService } from '../../services'
import { sortBy } from 'lodash'
import { useTasksContext } from 'components/contexts/tasks.context'

const JBUILDER_TEMPLATE_PREFIX = 'hits'

const YardTaskFacilitySelect = () => {
  const [search, setSearch] = useState<string>('')
  const [options, setOptions] = useState([])
  const [facility, setFacility] = useState([])

  const {
    state: { selectedFacility },
    actions: { setSelectedFacility }
  } = useTasksContext()

  useDebounce(
    async () => {
      const { hits } = await facilityService.findFacilitiesWithoutPagination({
        search,
        filters: [
          {
            appointment_preferences: {
              enable_yard_management: true
            }
          }
        ],
        template: JBUILDER_TEMPLATE_PREFIX
      })
      setOptions(sortBy(hits, 'createdAt'))
      if (hits?.length >= 1) {
        onChange([hits[0]])
      }
    },
    200,
    [search]
  )

  useEffect(() => {
    if (selectedFacility) {
      setFacility(selectedFacility)
    }
  }, [selectedFacility])

  const onChange = (
    value: {
      id: string
      label: string
    }[]
  ) => {
    setSearch('')
    setSelectedFacility(value)
  }

  return (
    <Select
      clearable={false}
      backspaceClearsInputValue
      maxDropdownHeight="250px"
      deleteRemoves
      value={facility}
      onChange={({ value }) => {
        onChange(value)
      }}
      placeholder=""
      options={options}
      labelKey="name"
      onInputChange={e => {
        setSearch(e.currentTarget.value)
      }}
      minWidth="135px"
      maxWidth="298px"
    />
  )
}

export default YardTaskFacilitySelect
