import React, { useState, useEffect, useContext, useMemo } from 'react'
import FormControl from 'components/ui/generic/FormControl'
import { Redirect, useHistory } from 'react-router-dom'
import { FlexGridItem, FlexGrid } from 'baseui/flex-grid'
import { StyledLink } from 'baseui/link'
import { TrixEditor } from 'react-trix'
import { HeadingXSmall } from 'baseui/typography'
import { Tab } from 'baseui/tabs'
import { useTranslation } from 'react-i18next'
import { useStyletron } from 'baseui'
import { SHIPPER } from 'components/models/User'
import { facilityService } from 'components/services/facility.service'
import { ErrorMessageButton } from 'components/components/ErrorMessageButton'
import Header from 'components/ui/generic/Header'
import Select from 'components/ui/generic/Select'
import Input from 'components/ui/generic/Input'
import Checkbox from 'components/ui/generic/Checkbox'
import TimezonePicker from 'components/ui/generic/TimezonePicker'
import { Block } from 'baseui/block'
import { CaretLeft } from '@phosphor-icons/react'
import Tabs from './Tabs'
import CustomTimePicker from '../../shared/custom-time-picker'
import StyledSpinner from '../../shared/styled-spinner'
import { fancyToast } from '../../utils'
import CreateBlock from '../modals/create-block'
import authenticatedFetch from '../../utils/authenticated-fetch'
import { CurrentUserContext } from '../../homepage/current-user-context'
import { DocksSearch } from '../../docks/DocksSearch'
import { AppointmentPreferenceForm } from '../AppointmentPreferenceForm'
import { OpenSchedulingCheckBox, FooterRow, QrCodeContainer } from './FacilityForm.styled'
import VPassQRCodeGeneratorModal from '../VPassQRCodeGeneratorModal'
import Button from 'components/ui/specific/PrimaryButton'
import Zones from '../../pages/Zones/Zones'

export const FacilityForm = ({ match }) => {
  const { currentUser } = useContext(CurrentUserContext)
  const [facility, setFacility] = useState<any>({})
  const [userLabels, setUserLabels] = useState([])
  const [loading, setIsLoading] = useState<boolean>(false)
  const [redirect, setRedirect] = useState<string>(null)
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [vPassModal, setVPassModalOpen] = useState<boolean>(false)
  const [activeKey, setActiveKey] = useState<string>('0')
  const history = useHistory()
  const isAdmin = useMemo(() => !!currentUser?.admin, [currentUser?.admin])
  const { t } = useTranslation()
  const [css, theme] = useStyletron()

  const requiredFieldsPresent = [
    {
      label: t('Facilities.Form.Validations.FacilityManagerRequired.Text'),
      status: !!facility.userId
    },
    { label: t('Facilities.Form.Validations.NameIsRequired.Text'), status: !!facility.name },
    { label: t('Facilities.Form.Validations.AddressIsRequired.Text'), status: !!facility.address },
    {
      label: t('Facilities.Form.Validations.TimezoneIsRequired.Text'),
      status: !!facility.timeZone
    },
    {
      label: t('Facilities.Form.Validations.OpenTimeIsRequired.Text'),
      status: !!facility.openTime
    },
    {
      label: t('Facilities.Form.Validations.CloseTimeIsRequired.Text'),
      status: !!facility.closeTime
    },
    {
      label: t('Facilities.Form.Validations.FacilityCodeCharactersMaximum.Text'),
      status: facility?.siteCode?.length <= 6 || !facility.siteCode
    },
    {
      label: t('Facilities.Form.Validations.DifferentOpenCloseTimeAreRequired.Text'),
      status:
        !facility.appointmentPreference?.differentOpenCloseTimeForDrop ||
        (!!facility.openTimeForDrop && !!facility.closeTimeForDrop)
    }
  ]

  useEffect(() => {
    if (!['new', 'create'].includes(match.params.handle)) {
      // "handle" is the facility_id
      facilityService.getFacility(match.params.handle).then(facility => setFacility(facility))
    } else if (!isAdmin) {
      history.replace('/facilities')
    }
  }, [history, isAdmin, match.params.handle])

  useEffect(() => {
    currentUser?.shipperId &&
      currentUser.userType === SHIPPER &&
      authenticatedFetch({
        path: `/shippers/${currentUser.shipperId}/users_list.json`
      })
        .then(([userLabels, _status]) => {
          setUserLabels(userLabels)
        })
        .catch(console.log)
  }, [currentUser])

  useEffect(() => {
    const selectedUser = userLabels.find(user => user.id === (facility.owner && facility.owner.id))
    if (selectedUser) {
      setFacility({ ...facility, userId: selectedUser.id, selectedUser })
    }
  }, [userLabels, facility.id])

  const submitFacilityForm = async () => {
    setIsLoading(true)
    const [json, status] = await authenticatedFetch({
      path: facility.id ? `/facilities/${facility.id}.json` : `/facilities.json`,
      method: facility.id ? 'PATCH' : 'POST',
      body: {
        facility: {
          ...facility,
          openTime: facility.openTime[0].id,
          closeTime: facility.closeTime[0].id,
          openTimeForDrop: facility.openTimeForDrop[0]?.id,
          closeTimeForDrop: facility.closeTimeForDrop[0]?.id
        }
      }
    })
    fancyToast(json, status)

    if (status === 201) {
      setTimeout(() => {
        setRedirect('/facilities')
      }, 2000)
    } else {
      setIsLoading(false)
    }
  }

  if (redirect) {
    return <Redirect to={redirect} />
  }

  if (!currentUser) {
    return <StyledSpinner />
  }

  return (
    <>
      <Header
        title={
          <div
            className={css({ display: 'flex', gap: theme.sizing.scale400, alignItems: 'center' })}>
            <StyledLink href="/facilities">
              <CaretLeft />
            </StyledLink>{' '}
            {facility?.name || t('HeaderNavigation.NavBarLinks.Facilities.Text')}
          </div>
        }
      />
      <Tabs
        activeKey={activeKey}
        onChange={params => {
          setActiveKey(params.activeKey.toString())
        }}>
        <Tab title={t('Facilities.Tabs.Facility.Text')}>
          <CreateBlock
            isOpen={openModal}
            close={() => {
              setOpenModal(false)
            }}
            facility={facility}
          />

          <Block maxWidth={'720px'}>
            <HeadingXSmall marginTop="0" marginBottom="scale800">
              {t('Facilities.Form.Header.Text')}
            </HeadingXSmall>
            <FlexGrid flexGridColumnCount={1} flexGridRowGap="scale200">
              <FlexGridItem>
                <FormControl label={t('Facilities.Form.Fields.FacilityManager.Label.Text')}>
                  <Select
                    required
                    options={userLabels}
                    disabled={!isAdmin}
                    placeholder={
                      facility.selectedUser
                        ? ''
                        : t('Facilities.Form.Fields.FacilityManager.PlaceHolder.Text')
                    }
                    value={facility.selectedUser}
                    searchable={false}
                    clearable={false}
                    onChange={params => {
                      setFacility({
                        ...facility,
                        userId: params.option.id,
                        selectedUser: params.value
                      })
                    }}
                  />
                </FormControl>
              </FlexGridItem>
              <FlexGridItem>
                <FormControl label={t('Facilities.Form.Fields.Name.Label.Text')}>
                  <Input
                    autoComplete="off"
                    required
                    disabled={!isAdmin}
                    name="facility[name]"
                    value={facility.name}
                    onChange={e => {
                      setFacility({
                        ...facility,
                        name: e.currentTarget.value
                      })
                    }}
                  />
                </FormControl>
              </FlexGridItem>
              <FlexGridItem>
                <FormControl label={t('Facilities.Form.Fields.Address.Label.Text')}>
                  <Input
                    autoComplete="off"
                    required
                    disabled={!isAdmin}
                    name="facility[address]"
                    value={facility.address}
                    onChange={evt => {
                      setFacility({
                        ...facility,
                        address: evt.currentTarget.value
                      })
                    }}
                  />
                </FormControl>
              </FlexGridItem>
              <FlexGridItem>
                <FormControl label={t('Facilities.Form.Fields.TimeZone.Label.Text')}>
                  <TimezonePicker
                    disabled={!isAdmin}
                    value={facility?.timeZone}
                    onChange={({ id }) => setFacility({ ...facility, timeZone: id })}
                  />
                </FormControl>
              </FlexGridItem>
              <FlexGridItem>
                <FlexGrid flexGridColumnCount={2} flexGridColumnGap="scale800">
                  <FlexGridItem>
                    <FormControl label={t('Facilities.Form.Fields.OpenTime.Label.Text')}>
                      <CustomTimePicker
                        disabled={!isAdmin}
                        placeholder={t('Facilities.Form.Fields.OpenTime.PlaceHolder.Text')}
                        value={facility.openTime}
                        onChange={({ value }) => {
                          setFacility({ ...facility, openTime: value })
                        }}
                      />
                    </FormControl>
                  </FlexGridItem>
                  <FlexGridItem>
                    <FormControl label={t('Facilities.Form.Fields.CloseTime.Label.Text')}>
                      <CustomTimePicker
                        disabled={!isAdmin}
                        placeholder={t('Facilities.Form.Fields.CloseTime.PlaceHolder.Text')}
                        value={facility.closeTime}
                        onChange={({ value }) => {
                          setFacility({ ...facility, closeTime: value })
                        }}
                      />
                    </FormControl>
                  </FlexGridItem>
                </FlexGrid>
                <FlexGrid>
                  <Checkbox
                    checked={facility.appointmentPreference?.differentOpenCloseTimeForDrop}
                    onChange={e =>
                      setFacility({
                        ...facility,
                        appointmentPreference: {
                          ...facility.appointmentPreference,
                          differentOpenCloseTimeForDrop:
                            !facility.appointmentPreference?.differentOpenCloseTimeForDrop
                        }
                      })
                    }
                    label={t('Facilities.Form.Fields.DifferentOpenCloseTime.Label.Text')}
                  />
                </FlexGrid>
              </FlexGridItem>
              {facility.appointmentPreference?.differentOpenCloseTimeForDrop && (
                <FlexGridItem>
                  <FlexGrid flexGridColumnCount={2} flexGridColumnGap="scale800">
                    <FlexGridItem>
                      <FormControl label={t('Facilities.Form.Fields.OpenTimeForDrop.Label.Text')}>
                        <CustomTimePicker
                          disabled={!isAdmin}
                          placeholder={t('Facilities.Form.Fields.OpenTime.PlaceHolder.Text')}
                          value={facility.openTimeForDrop}
                          onChange={({ value }) => {
                            setFacility({ ...facility, openTimeForDrop: value })
                          }}
                        />
                      </FormControl>
                    </FlexGridItem>
                    <FlexGridItem>
                      <FormControl label={t('Facilities.Form.Fields.CloseTimeForDrop.Label.Text')}>
                        <CustomTimePicker
                          disabled={!isAdmin}
                          placeholder={t('Facilities.Form.Fields.CloseTime.PlaceHolder.Text')}
                          value={facility.closeTimeForDrop}
                          onChange={({ value }) => {
                            setFacility({ ...facility, closeTimeForDrop: value })
                          }}
                        />
                      </FormControl>
                    </FlexGridItem>
                  </FlexGrid>
                </FlexGridItem>
              )}
              <FlexGridItem>
                <FlexGrid flexGridColumnCount={2}>
                  <FlexGridItem>
                    <FormControl label={t('Facilities.Form.Fields.FacilityCode.Label.Text')}>
                      <Input
                        autoComplete="off"
                        disabled={!isAdmin}
                        name="facility[siteCode]"
                        value={facility?.siteCode}
                        onChange={evt => {
                          setFacility({
                            ...facility,
                            siteCode: evt.currentTarget.value
                          })
                        }}
                      />
                    </FormControl>
                  </FlexGridItem>
                  <FlexGridItem>
                    <QrCodeContainer>
                      <Button onClick={() => setVPassModalOpen(true)}>
                        {t('Facilities.Form.GenerateQrCodeButton.Text')}
                      </Button>
                    </QrCodeContainer>
                  </FlexGridItem>
                </FlexGrid>
              </FlexGridItem>
              <FlexGridItem>
                {isAdmin && (
                  <FormControl
                    label={t('Facilities.Form.Fields.TermsOfService.Label.Text')}
                    caption={t('Facilities.Form.Fields.TermsOfService.SubTitle.Text')}>
                    <>
                      {('note' in facility || match.params.handle == 'new') && (
                        <TrixEditor
                          value={facility.note}
                          onChange={text => {
                            setFacility({ ...facility, note: text })
                          }}
                          onEditorReady={() => {}}
                          mergeTags={[]}
                        />
                      )}
                    </>
                  </FormControl>
                )}
              </FlexGridItem>
            </FlexGrid>
            <VPassQRCodeGeneratorModal open={vPassModal} onClose={setVPassModalOpen} />
            <OpenSchedulingCheckBox>
              <Checkbox
                checked={facility.openScheduling}
                onChange={e =>
                  setFacility({ ...facility, openScheduling: !facility.openScheduling })
                }
                label={t('Facilities.Form.Fields.AllowOpenScheduling.Label.Text')}
              />
            </OpenSchedulingCheckBox>
            <FooterRow>
              {isAdmin && (
                <ErrorMessageButton
                  errors={requiredFieldsPresent}
                  onClick={submitFacilityForm}
                  disabled={
                    !isAdmin ||
                    facility?.siteCode?.length > 6 ||
                    !facility.userId ||
                    !facility.name ||
                    !facility.address ||
                    !facility.openTime ||
                    !facility.closeTime ||
                    !facility.timeZone ||
                    !(
                      !facility.appointmentPreference?.differentOpenCloseTimeForDrop ||
                      (!!facility.openTimeForDrop && !!facility.closeTimeForDrop)
                    )
                  }
                  isLoading={loading}
                  statefulTooltipProps={{ placement: 'top' }}
                  label={
                    facility.id
                      ? t('Facilities.Form.SaveButton.Alternatives.Save.Text')
                      : t('Facilities.Form.SaveButton.Alternatives.Create.Text')
                  }
                />
              )}
            </FooterRow>
          </Block>
        </Tab>
        <Tab title={t('Facilities.Tabs.AppointmentPreferences.Text')}>
          <AppointmentPreferenceForm facility={facility} />
        </Tab>
        <Tab title={t('Facilities.Tabs.Docks.Text')}>
          <DocksSearch facility={facility} />
        </Tab>
        {facility?.appointmentPreference?.enableYardManagement && (
          <Tab title={t('Facilities.Tabs.Yard.Text')}>
            <Zones facility={facility} />
          </Tab>
        )}
      </Tabs>
    </>
  )
}
