import React, { useEffect, useState } from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'baseui/modal'
import CustomPanel from './custom-panel'
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid'
import Tags from './Tags'
import Button from 'components/ui/specific/PrimaryButton'
import { PencilSimple, X } from '@phosphor-icons/react'
import { KIND } from 'baseui/button'
import EventIcon from 'components/ui/specific/EventIcon'
import { useStyletron } from 'baseui'
import useDropAppointments from '../../hooks/use-drop-appointments'
import moment from 'moment'
import Milestones from './../edit-appointment-modal/MilestonesSection'
import AuditLogs from './../edit-appointment-modal/audit-logs'
import { useTranslation } from 'react-i18next'
import Tabs from 'components/ui/generic/Tabs'
import { Tab } from 'baseui/tabs'
import {
  FlexGridFooterProps,
  FlexGridHeaderProps,
  FlexGridHeaderSubtitleProps,
  FlexGridPanelContentProps,
  IconEquipmentTypeDiv,
  ModalDropProps,
  TabPanelProps,
  TextContainer,
  TextLine,
  itemProps
} from './edit-drop-appointment-modal.styled'
import AppointmentEditForm from './AppointmentEditForm'
import { useAppointmentContext } from 'components/contexts/appointment-context'
import { SpinnerWrapper } from 'components/pages/UserRoles/UserRolesTable.styled'
import StyledSpinner from 'components/shared/styled-spinner'
import { DROP, LIVE } from 'components/constants/handling_method'

const EditDropAppointment = (props: ModalProps) => {
  const { selectedEvent, isOpen, close } = props
  const [shouldReload, setShouldReload] = useState(false)
  const { appointment, appointmentData, loading } = useDropAppointments(
    selectedEvent.id,
    shouldReload
  )
  const {
    actions: { setEditAppointment, loadAppointmentData, resetAppointmentStates }
  } = useAppointmentContext()
  const [activeTab, setActiveTab] = useState<string>('0')
  const [css, theme] = useStyletron()
  const { t } = useTranslation()
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)

  const onEditAppointment = () => {
    setEditAppointment(selectedEvent.id)
    setIsEditModalOpen(true)
  }

  useEffect(() => {
    loadAppointmentData(appointmentData)
  }, [appointmentData])

  const onCloseEditModal = reload => {
    setIsEditModalOpen(false)
    reload && setShouldReload(prev => !prev)
  }

  const onDeleted = () => {
    resetAppointmentStates()
    close()
  }

  const onCloseShowModal = () => {
    resetAppointmentStates()
    close()
  }

  if (!appointment) {
    return <></>
  }

  return (
    <>
      <Modal
        size="auto"
        unstable_ModalBackdropScroll
        onClose={onCloseShowModal}
        isOpen={isOpen}
        {...ModalDropProps}>
        <ModalHeader>{appointment.confirmationId}</ModalHeader>
        <ModalBody>
          {loading ? (
            <SpinnerWrapper>
              <StyledSpinner />
            </SpinnerWrapper>
          ) : (
            <>
              <FlexGrid
                flexGridColumnCount={3}
                flexGridColumnGap="scale900"
                {...FlexGridHeaderProps}>
                <FlexGridItem {...FlexGridHeaderSubtitleProps}>
                  <div>
                    <IconEquipmentTypeDiv>
                      <EventIcon
                        event={{
                          extendedProps: {
                            equipmentTypeId: appointment.equipmentTypeId,
                            daysOfWeek: false
                          }
                        }}
                        hideColor
                      />
                    </IconEquipmentTypeDiv>
                    <span
                      className={css({
                        fontSize: theme.typography.LabelMedium.fontSize,
                        fontWeight: theme.typography.HeadingMedium.fontWeight
                      })}>
                      {appointment.schedulerName}
                    </span>
                  </div>
                  <div>
                    <span data-testid={'show-appointment-header-date'}>
                      <small>{appointment.headerDate}</small>
                    </span>
                  </div>
                </FlexGridItem>
                <FlexGridItem display="none"></FlexGridItem>
              </FlexGrid>
              <CustomPanel
                initialState={{
                  expanded: true
                }}
                title={t('Appointments.ShowAppointmentModal.PanelsAppointmentDetails.Header.Text')}>
                <FlexGrid
                  flexGridColumnCount={2}
                  flexGridColumnGap="scale900"
                  {...FlexGridPanelContentProps}>
                  <FlexGridItem {...itemProps}>
                    <TextContainer>
                      <TextLine>
                        <small>
                          {t(
                            'Appointments.ShowAppointmentModal.PanelsAppointmentDetails.ArrivalDate.Text'
                          )}
                        </small>
                      </TextLine>
                      <TextLine>{moment(appointment.arrivalTime).format('DD/MM/YYYY')}</TextLine>
                    </TextContainer>
                  </FlexGridItem>
                  <FlexGridItem {...itemProps}>
                    <TextContainer>
                      <TextLine>
                        <small>
                          {t(
                            'Appointments.ShowAppointmentModal.PanelsAppointmentDetails.ArrivalTime.Text'
                          )}
                        </small>
                      </TextLine>
                      <TextLine>{moment(appointment.arrivalTime).format('h:mm a')}</TextLine>
                    </TextContainer>
                  </FlexGridItem>
                  <FlexGridItem {...itemProps}>
                    <TextContainer>
                      <TextLine>
                        <small>
                          {t(
                            'Appointments.ShowAppointmentModal.PanelsAppointmentDetails.Facility.Text'
                          )}
                        </small>
                      </TextLine>
                      <TextLine>{appointment.facilityName}</TextLine>
                    </TextContainer>
                  </FlexGridItem>
                  <FlexGridItem {...itemProps}>
                    <TextContainer>
                      <TextLine>
                        <small>
                          {t(
                            'Appointments.ShowAppointmentModal.PanelsAppointmentDetails.Scheduler.Text'
                          )}
                        </small>
                      </TextLine>
                      <TextLine>{appointment.schedulerName}</TextLine>
                    </TextContainer>
                  </FlexGridItem>
                  <FlexGridItem {...itemProps}>
                    <TextContainer>
                      <TextLine>
                        <small>
                          {t(
                            'Appointments.ShowAppointmentModal.PanelsAppointmentDetails.Handling.Text'
                          )}
                        </small>
                      </TextLine>
                      <TextLine>{appointment.handlingMethod}</TextLine>
                    </TextContainer>
                  </FlexGridItem>
                  {appointment.carrierName && (
                    <FlexGridItem {...itemProps}>
                      <TextContainer>
                        <TextLine>
                          <small>
                            {t(
                              'Appointments.ShowAppointmentModal.PanelsAppointmentDetails.Carrier.Text'
                            )}
                          </small>
                        </TextLine>
                        <TextLine>{appointment.carrierName}</TextLine>
                      </TextContainer>
                    </FlexGridItem>
                  )}
                  {appointment.handlingMethod === DROP && (
                    <FlexGridItem {...itemProps}>
                      <TextContainer>
                        <TextLine>
                          <small>
                            {t(
                              'Appointments.ShowAppointmentModal.PanelsAppointmentDetails.TypeOfAppointment.Text'
                            )}
                          </small>
                        </TextLine>
                        <TextLine>{appointment.appointmentType}</TextLine>
                      </TextContainer>
                    </FlexGridItem>
                  )}
                  {appointment.handlingMethod === LIVE && (
                    <>
                      <FlexGridItem key={1} {...itemProps}>
                        <TextContainer>
                          <TextLine>
                            <small>
                              {t(
                                'Appointments.ShowAppointmentModal.TrailerDetails.EquipmentType.Label'
                              )}
                            </small>
                          </TextLine>
                          <TextLine>{appointment.equipmentTypeName}</TextLine>
                        </TextContainer>
                      </FlexGridItem>
                      <FlexGridItem key={2} {...itemProps}>
                        <TextContainer>
                          <TextLine>
                            <small>
                              {t(
                                'Appointments.ShowAppointmentModal.TrailerDetails.shipmentId.Label'
                              )}
                            </small>
                          </TextLine>
                          <TextLine>
                            <Tags tags={appointment.purchaseOrdersAttributes} />
                          </TextLine>
                        </TextContainer>
                      </FlexGridItem>
                    </>
                  )}
                </FlexGrid>
              </CustomPanel>
              {appointment.inbound && (
                <CustomPanel
                  title={t('Appointments.ShowAppointmentModal.TrailerDetails.Header.Text', {
                    type: appointment.inbound?.appointmentType
                  })}>
                  <FlexGrid
                    flexGridColumnCount={2}
                    flexGridColumnGap="scale900"
                    {...FlexGridPanelContentProps}>
                    <FlexGridItem {...itemProps}>
                      <TextContainer>
                        <TextLine>
                          <small>
                            {t(
                              'Appointments.ShowAppointmentModal.TrailerDetails.EquipmentType.Text',
                              {
                                type: appointment.inbound?.appointmentType
                              }
                            )}
                          </small>
                        </TextLine>
                        <TextLine>{appointment.inbound?.equipmentType}</TextLine>
                      </TextContainer>
                    </FlexGridItem>
                    <FlexGridItem {...itemProps}>
                      <TextContainer>
                        <TextLine>
                          <small>
                            {t(
                              'Appointments.ShowAppointmentModal.TrailerDetails.TrailerStatus.Text',
                              {
                                type: appointment.inbound?.appointmentType
                              }
                            )}
                          </small>
                        </TextLine>
                        <TextLine>{appointment.inbound?.trailer?.status}</TextLine>
                      </TextContainer>
                    </FlexGridItem>
                    <FlexGridItem {...itemProps}>
                      <TextContainer>
                        <TextLine>
                          <small>
                            {t('Appointments.ShowAppointmentModal.TrailerDetails.Trailer.Text', {
                              type: appointment.inbound?.appointmentType
                            })}
                          </small>
                        </TextLine>
                        <TextLine>{appointment.inbound?.trailer?.number}</TextLine>
                      </TextContainer>
                    </FlexGridItem>
                    <FlexGridItem {...itemProps}>
                      <TextContainer>
                        <TextLine>
                          <small>
                            {t('Appointments.ShowAppointmentModal.TrailerDetails.shipmentId.Text', {
                              type: appointment.inbound?.appointmentType
                            })}
                          </small>
                        </TextLine>
                        <TextLine>
                          <Tags tags={appointment.inbound?.purchaseOrdersAttributes} />
                        </TextLine>
                      </TextContainer>
                    </FlexGridItem>
                    <FlexGridItem {...itemProps}>
                      <TextContainer>
                        <TextLine>
                          <small>
                            {t(
                              'Appointments.ShowAppointmentModal.TrailerDetails.TrailerState.Text',
                              {
                                type: appointment.inbound?.appointmentType
                              }
                            )}
                          </small>
                        </TextLine>
                        <TextLine>{appointment.inbound?.trailer?.state}</TextLine>
                      </TextContainer>
                    </FlexGridItem>
                  </FlexGrid>
                </CustomPanel>
              )}
              {appointment.outbound && (
                <CustomPanel
                  title={t('Appointments.ShowAppointmentModal.TrailerDetails.Header.Text', {
                    type: appointment.outbound?.appointmentType
                  })}>
                  <FlexGrid
                    flexGridColumnCount={2}
                    flexGridColumnGap="scale900"
                    {...FlexGridPanelContentProps}>
                    <FlexGridItem {...itemProps}>
                      <TextContainer>
                        <TextLine>
                          <small>
                            {t(
                              'Appointments.ShowAppointmentModal.TrailerDetails.EquipmentType.Text',
                              {
                                type: appointment.outbound?.appointmentType
                              }
                            )}
                          </small>
                        </TextLine>
                        <TextLine>{appointment.outbound?.equipmentType}</TextLine>
                      </TextContainer>
                    </FlexGridItem>
                    <FlexGridItem {...itemProps}>
                      <TextContainer>
                        <TextLine>
                          <small>
                            {t(
                              'Appointments.ShowAppointmentModal.TrailerDetails.TrailerStatus.Text',
                              {
                                type: appointment.outbound?.appointmentType
                              }
                            )}
                          </small>
                        </TextLine>
                        <TextLine>{appointment.outbound?.trailer?.status}</TextLine>
                      </TextContainer>
                    </FlexGridItem>
                    <FlexGridItem {...itemProps}>
                      <TextContainer>
                        <TextLine>
                          <small>
                            {' '}
                            {t('Appointments.ShowAppointmentModal.TrailerDetails.Trailer.Text', {
                              type: appointment.outbound?.appointmentType
                            })}
                          </small>
                        </TextLine>
                        <TextLine>{appointment.outbound?.trailer?.number}</TextLine>
                      </TextContainer>
                    </FlexGridItem>
                    <FlexGridItem {...itemProps}>
                      <TextContainer>
                        <TextLine>
                          <small>
                            {t('Appointments.ShowAppointmentModal.TrailerDetails.shipmentId.Text', {
                              type: appointment.outbound?.appointmentType
                            })}
                          </small>
                        </TextLine>
                        <TextLine>
                          <Tags tags={appointment.outbound?.purchaseOrdersAttributes} />
                        </TextLine>
                      </TextContainer>
                    </FlexGridItem>
                    <FlexGridItem {...itemProps}>
                      <TextContainer>
                        <TextLine>
                          <small>
                            {t(
                              'Appointments.ShowAppointmentModal.TrailerDetails.TrailerState.Text',
                              {
                                type: appointment.outbound?.appointmentType
                              }
                            )}
                          </small>
                        </TextLine>
                        <TextLine>{appointment.outbound?.trailer?.state}</TextLine>
                      </TextContainer>
                    </FlexGridItem>
                  </FlexGrid>
                </CustomPanel>
              )}
              <CustomPanel
                title={t('Appointments.ShowAppointmentModal.Milestone.Header.Text')}
                tag={appointment.status}>
                <Tabs
                  onChange={({ activeKey }) => {
                    setActiveTab(activeKey as any)
                  }}
                  activeKey={activeTab}>
                  <Tab
                    {...TabPanelProps}
                    title={t('Appointments.EditAppointmentModal.Milestones.Header.Text')}>
                    <Milestones appointment={appointment} setAppointment={e => {}} />
                  </Tab>
                  <Tab
                    {...TabPanelProps}
                    title={t('Appointments.EditAppointmentModal.AuditLogs.Header.Text')}>
                    <AuditLogs appointment={appointment} isShowDropAppointment />
                  </Tab>
                </Tabs>
              </CustomPanel>
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <FlexGrid flexGridColumnGap="scale900" {...FlexGridFooterProps}>
            <Button onClick={onCloseShowModal} kind={KIND.secondary} IconStart={<X />}>
              {t('Appointments.ShowAppointmentModal.Actions.Cancel.Text')}
            </Button>
            <Button onClick={onEditAppointment} IconStart={<PencilSimple />}>
              {t('Appointments.ShowAppointmentModal.Actions.Edit.Text')}
            </Button>
          </FlexGrid>
        </ModalFooter>
      </Modal>
      {isEditModalOpen && (
        <AppointmentEditForm
          onClose={onCloseEditModal}
          isOpen={isEditModalOpen}
          onDeleted={onDeleted}
        />
      )}
    </>
  )
}

export default EditDropAppointment
