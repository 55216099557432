import React, { useState, useEffect, useCallback } from 'react'
import Select from 'components/ui/generic/Select'
import useIsMounted from 'components/hooks/use-is-mounted'
import { useTranslation } from 'react-i18next'
import { useTasksContext } from 'components/contexts/tasks.context'
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid'
import { FormControl } from 'baseui/form-control'
import { facilityService, slotService } from 'components/services'

const TO_OPTIONS_DOCK = 'dock'
const TO_OPTIONS_SLOT = 'slot'

const DestinationOption = [
  { label: 'Dock Doors', id: TO_OPTIONS_DOCK },
  { label: 'Yard Slot', id: TO_OPTIONS_SLOT }
]

const SelectToField = () => {
  const { state, actions } = useTasksContext()
  const {
    taskModal: { task, isEdit, trailer }
  } = state
  const { setTask } = actions

  const isMounted = useIsMounted()
  const [loading, setLoading] = useState(false)
  const [hits, setHits] = useState([])
  const [toOption, setToOption] = useState<string>(null)
  const { t } = useTranslation()

  const convertToSelect = array => {
    return Array.isArray(array) ? array.map(item => ({ label: item.name, id: item.id })) : []
  }

  const fetchDocks = useCallback(async () => {
    if (task?.facilityId && toOption) {
      setLoading(true)
      const json = await facilityService.getFacilityDocks(task?.facilityId)
      setHits(convertToSelect(json))
      setLoading(false)
    }
  }, [isMounted, task?.facilityId, toOption])

  const fetchSlots = useCallback(async () => {
    if (task?.facilityId && toOption) {
      setLoading(true)
      const [json, _status] = await slotService.getAllSlots(task?.facilityId)
      if (json.results) {
        setHits(convertToSelect(json.results))
      }
      setLoading(false)
    }
  }, [isMounted, task?.facilityId, toOption])

  const destinationsOptionsFiltered = () => {
    return DestinationOption.filter(
      option => !(trailer?.trailerInfo?.dock && option.id === TO_OPTIONS_DOCK)
    )
  }

  const clearSelect = () => {
    setTask({
      ...task,
      dockId: null,
      toSlotId: null
    })
  }

  useEffect(() => {
    if (trailer?.trailerInfo?.dock) {
      setToOption(TO_OPTIONS_SLOT)
      setHits([])
    }
  }, [trailer?.trailerInfo?.dock])

  useEffect(() => {
    if (toOption === TO_OPTIONS_DOCK) {
      fetchDocks()
    }
    if (toOption === TO_OPTIONS_SLOT) {
      fetchSlots()
    }
  }, [task?.facilityId, toOption])

  const onChange = option => {
    if (toOption === TO_OPTIONS_DOCK) {
      setTask({
        ...task,
        dockId: option.id
      })
    }
    if (toOption === TO_OPTIONS_SLOT) {
      setTask({
        ...task,
        toSlotId: option.id
      })
    }
  }

  const getValue = () => {
    if (toOption === TO_OPTIONS_DOCK) {
      return hits.find(f => f.id === task?.dockId) || []
    }
    if (toOption === TO_OPTIONS_SLOT) {
      return hits.find(f => f.id === task?.toSlotId) || []
    }
  }

  const getLabel = () => {
    if (toOption === TO_OPTIONS_DOCK) {
      return t('Common.Fields.Dock.Text')
    }
    if (toOption === TO_OPTIONS_SLOT) {
      return t('Common.Fields.Slot.Text')
    }
  }

  useEffect(() => {
    if (isMounted.current) {
      if (task?.facilityId && isEdit) {
        if (task?.dockId) setToOption(TO_OPTIONS_DOCK)
        if (task?.toSlotId) setToOption(TO_OPTIONS_SLOT)
      } else {
        setToOption(DestinationOption[0].id)
      }
    }
    return () => {
      isMounted.current = false
    }
  }, [isMounted, isEdit])

  return (
    <FlexGrid flexGridColumnCount={[1, 1, 2]} flexGridColumnGap={'scale600'}>
      <FlexGridItem>
        <FormControl>
          <Select
            maxDropdownHeight="250px"
            label={t('YardTask.Kanban.TaskModal.Fields.To.Text')}
            value={destinationsOptionsFiltered().find(f => f.id === toOption)}
            searchable={false}
            clearable={false}
            placeholder=""
            onChange={({ option }) => {
              setToOption(option.id)
              clearSelect()
            }}
            options={destinationsOptionsFiltered()}
          />
        </FormControl>
      </FlexGridItem>
      <FlexGridItem>
        <FormControl>
          <Select
            isLoading={loading}
            maxDropdownHeight="250px"
            label={getLabel()}
            value={getValue()}
            searchable={false}
            clearable={false}
            placeholder=""
            onChange={({ option }) => {
              onChange(option)
            }}
            options={hits}
          />
        </FormControl>
      </FlexGridItem>
    </FlexGrid>
  )
}

export default SelectToField
