export const YardHeaderNavegationOverrides = () => ({
  Root: {
    style: ({ $theme }) => {
      return {
        paddingTop: $theme.sizing.scale600,
        paddingBottom: $theme.sizing.scale600,
        paddingLeft: $theme.sizing.scale600,
        paddingRight: $theme.sizing.scale600,
        marginLeft: '-16px',
        marginRight: '-16px',
        backgroundColor: '#ECF2FF',
        borderBottomColor: $theme.colors.transparent,
        boxShadow: '1000px 0px #ECF2FF, -1000px 0px #ECF2FF',
        [$theme.mediaQuery.large]: {
          paddingLeft: 0,
          paddingRight: 0,
          marginLeft: 0,
          marginRight: 0
        }
      }
    }
  }
})

export const YardNavLinkClassName = theme => ({
  color: 'inherit',
  textDecoration: 'none',
  fontSize: theme.typography.LabelXSmall.fontSize,
  display: 'flex',
  gap: theme.sizing.scale300,
  alignItems: 'center',
  [theme.mediaQuery.large]: {
    fontSize: '13px'
  }
})
