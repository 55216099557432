// We have to import this @fullcalendar/react before 'Draggable. Something about tree shaking.
// https://github.com/fullcalendar/fullcalendar-angular/issues/306#issuecomment-647591856
import '@fullcalendar/react'
import React, { useContext, useEffect, useRef, useState } from 'react'
import CyberSearch from 'cyber/search/search'
import { CalendarReferenceContext } from 'components/contexts/calendar-reference-context'
import { useLocalStorage } from 'react-use'
import {
  CustomLabels,
  INITIAL_CUSTOM_FIELD_NAMES,
  CustomLabelKey,
  CustomLabelsContext
} from 'components/contexts/custom-labels-context'
import { CurrentUserContext } from 'components/homepage/current-user-context'
import { FacilitiesContext } from '../../shipper/instant-calendar/context/facilities-context'
import { CalendarSelectedOptionsProvider } from '../../contexts/calendar-selected-options.context'
import { DEFAULT_LOCALE_STRING_LANGUAGE } from '../../constants/default-locale-string-language'
import moment from 'moment'
import { YardScheduleProvider } from '../../contexts/yard-schedule.context'
import YardScheduleCalendar from './YardScheduleCalendar'
import Header from 'components/ui/generic/Header'
import { useTranslation } from 'react-i18next'
import YardScheduleFacilitySelect from './YardScheduleFacilitySelect'
import YardScheduleAppTypeSelect from './YardScheduleAppTypeSelect'
import YardScheduleSearchBox from './YardScheduleSearchBox'

const LOCAL_STORAGE_DATE_KEY = 'YARD_SCHEDULE_SELECTED_DATE_BY_USER_AND_ORG'
const LOCAL_STORAGE_TIME_RANGE_KEY = 'YARD_SCHEDULE_TIME_RANGE_BY_USER_AND_ORG'

const INITIAL_CACHE_TIME_RANGE = {
  minTime: [
    {
      id: '1970-01-01T00:00',
      label: '00:00'
    }
  ],
  maxTime: [{ id: '1970-01-01T24:00', label: '24:00' }]
}

const INDEX_NAME = 'appointment'

const QUERY_BY = 'trailer_number'

const HITS_PER_PAGE = 100

const Schedule = () => {
  const calendarReference = useRef(null)
  const { currentUser } = useContext(CurrentUserContext)
  const [selectedEvent, setSelectedEvent] = useState<SelectedEvent>(null)
  const [numberOfHits, setNumberOfHits] = useState<number>(0)
  const [selectedDate, setSelectedDate] = useState(new Date())
  const { t } = useTranslation()

  const [cachedTimeRange, setCachedTimeRange] = useLocalStorage(
    LOCAL_STORAGE_TIME_RANGE_KEY,
    INITIAL_CACHE_TIME_RANGE
  )

  const [customLabels, setCustomLabels] = useState<CustomLabels>(INITIAL_CUSTOM_FIELD_NAMES)

  const customLabel = (customLabelKey: CustomLabelKey) => customLabels[customLabelKey]

  const initialDate = [
    moment(new Date().toLocaleString(DEFAULT_LOCALE_STRING_LANGUAGE)).startOf('day').toDate(),
    moment(new Date().toLocaleString(DEFAULT_LOCALE_STRING_LANGUAGE)).endOf('day').toDate()
  ]

  const [cacheDate, setCacheDate] = useLocalStorage(LOCAL_STORAGE_DATE_KEY, {})

  const [dateInCalendarFilter, setDateInCalendarFilter] = useState(
    cacheDate &&
      cacheDate[currentUser.shipperId] &&
      cacheDate[currentUser.shipperId][currentUser.id] &&
      cacheDate[currentUser.shipperId][currentUser.id][0] &&
      cacheDate[currentUser.shipperId][currentUser.id][1]
      ? [
          moment(
            new Date(cacheDate[currentUser.shipperId][currentUser.id][0]).toLocaleString(
              DEFAULT_LOCALE_STRING_LANGUAGE
            )
          )
            .startOf('day')
            .toDate(),
          moment(
            new Date(cacheDate[currentUser.shipperId][currentUser.id][1]).toLocaleString(
              DEFAULT_LOCALE_STRING_LANGUAGE
            )
          )
            .endOf('day')
            .toDate()
        ]
      : initialDate
  )

  useEffect(() => {
    if (
      cacheDate &&
      cacheDate[currentUser.shipperId] &&
      cacheDate[currentUser.shipperId][currentUser.id] &&
      cacheDate[currentUser.shipperId][currentUser.id][0]
    ) {
      setSelectedDate(new Date(cacheDate[currentUser.shipperId][currentUser.id][0]))
    }
  }, [cacheDate])

  return (
    <CustomLabelsContext.Provider value={{ customLabels, setCustomLabels, customLabel }}>
      <CalendarReferenceContext.Provider
        value={{
          calendarReference,
          cacheDate,
          setCacheDate,
          setDateInCalendarFilter,
          dateInCalendarFilter
        }}>
        <FacilitiesContext.Provider
          value={{ facilities: [], slotDuration: '', selectedDate, setSelectedDate }}>
          <YardScheduleProvider>
            <CalendarSelectedOptionsProvider>
              <CyberSearch indexName={INDEX_NAME} queryBy={QUERY_BY} hitsPerPage={HITS_PER_PAGE}>
                <Header
                  title={t('YardSchedule.Header.Text')}
                  leftItems={[
                    <YardScheduleFacilitySelect />,
                    <YardScheduleAppTypeSelect />,
                    <YardScheduleSearchBox />
                  ]}
                />
                <YardScheduleCalendar
                  selectedEvent={selectedEvent}
                  setSelectedDate={setSelectedDate}
                  selectedDate={selectedDate}
                  setSelectedEvent={setSelectedEvent}
                  timeRange={
                    cachedTimeRange[currentUser.shipperId] &&
                    cachedTimeRange[currentUser.shipperId][currentUser.id]
                      ? cachedTimeRange[currentUser.shipperId][currentUser.id]
                      : cachedTimeRange
                  }
                  setNumberOfHits={setNumberOfHits}
                />
              </CyberSearch>
            </CalendarSelectedOptionsProvider>
          </YardScheduleProvider>
        </FacilitiesContext.Provider>
      </CalendarReferenceContext.Provider>
    </CustomLabelsContext.Provider>
  )
}

export default Schedule
