export const SET_SELECTED_FACILITY = 'SET_SELECTED_FACILITY'
export const UPDATE_DROP_CONFIRMATION_MODAL = 'UPDATE_DROP_CONFIRMATION_MODAL'
export const SET_DROP_MODAL_DRIVER_ASSIGNMENT = 'SET_DROP_MODAL_DRIVER_ASSIGNMENT'
export const SET_LOADING = 'SET_LOADING'
export const SET_SELECTED_YARD_JOCKEY = 'SET_SELECTED_YARD_JOCKEY'
export const SET_DROP_MODAL_LOADING = 'SET_DROP_MODAL_LOADING'
export const SET_ZONES = 'SET_ZONES'
export const SET_APP_TYPE = 'SET_APP_TYPE'
export const OPEN_DROP_MODAL = 'OPEN_DROP_MODAL'
export const CLOSE_DROP_MODAL = 'CLOSE_DROP_MODAL'
export const CLOSE_DROP_MODAL_KEEP_DATA_OPEN_CONFIRMATION_MODAL =
  'CLOSE_DROP_MODAL_KEEP_DATA_OPEN_CONFIRMATION_MODAL'
export const SET_APP_TYPES_PER_CLASS = 'SET_APP_TYPES_PER_CLASS'
export const SET_DROP_MODAL_SELECTED_AREA = 'SET_DROP_MODAL_SELECTED_AREA'
export const SET_DROP_MODAL_SELECTED_SLOT = 'SET_DROP_MODAL_SELECTED_SLOT'
export const SET_DROP_MODAL_AREA_OPTIONS = 'SET_DROP_MODAL_AREA_OPTIONS'
export const SET_CACHE_HITS = 'SET_CACHE_HITS'

export const yardScheduleReducer = (state, action) => {
  switch (action.type) {
    case CLOSE_DROP_MODAL_KEEP_DATA_OPEN_CONFIRMATION_MODAL: {
      const {
        dropConfirmationModalIsOpen,
        dropModalIsOpen,
        dropConfirmationModalTitle,
        dropConfirmationTaskId,
        dropConfirmationSlotId,
        dropConfirmationDockId
      } = action.payload
      return {
        ...state,
        dropConfirmationModalIsOpen,
        dropConfirmationTaskId,
        dropModalIsOpen,
        dropConfirmationModalTitle,
        dropConfirmationSlotId,
        dropConfirmationDockId
      }
    }
    case UPDATE_DROP_CONFIRMATION_MODAL: {
      const {
        dropConfirmationModalIsOpen,
        dropConfirmationModalTitle,
        dropConfirmationSlotId,
        dropConfirmationDockId,
        dropConfirmationTaskId
      } = action.payload

      return {
        ...state,
        dropConfirmationModalIsOpen,
        dropConfirmationModalTitle,
        dropConfirmationSlotId,
        dropConfirmationDockId,
        dropConfirmationTaskId
      }
    }
    case SET_DROP_MODAL_DRIVER_ASSIGNMENT: {
      return {
        ...state,
        dropModalDriverAssignment: action.payload
      }
    }
    case SET_SELECTED_YARD_JOCKEY: {
      return {
        ...state,
        selectedYardJockey: action.payload
      }
    }
    case SET_SELECTED_FACILITY: {
      return {
        ...state,
        selectedFacility: action.payload
      }
    }
    case SET_APP_TYPES_PER_CLASS: {
      return {
        ...state,
        appTypesPerClass: action.payload
      }
    }
    case SET_LOADING: {
      return {
        ...state,
        loading: action.payload
      }
    }
    case SET_DROP_MODAL_LOADING: {
      return {
        ...state,
        dropModalLoading: action.payload
      }
    }
    case SET_ZONES: {
      return {
        ...state,
        zones: action.payload
      }
    }
    case SET_APP_TYPE: {
      return {
        ...state,
        selectedAppType: action.payload
      }
    }
    case OPEN_DROP_MODAL: {
      const {
        dropModalFromZoneName,
        dropModalAppointmentId,
        dropModalToZoneId,
        dropModalToZoneName,
        dropModalIsOpen,
        dropModalForDriver,
        selectedAppointment,
        info
      } = action.payload
      return {
        ...state,
        dropModalFromZoneName,
        dropModalAppointmentId,
        dropModalToZoneId,
        dropModalToZoneName,
        dropModalIsOpen,
        dropModalForDriver,
        selectedAppointment,
        info
      }
    }
    case CLOSE_DROP_MODAL: {
      const {
        dropModalFromZoneName,
        dropModalAppointmentId,
        dropModalToZoneId,
        dropModalToZoneName,
        dropModalIsOpen,
        selectedAppointment,
        dropModalDriverAssignment
      } = action.payload
      return {
        ...state,
        dropModalFromZoneName,
        dropModalAppointmentId,
        dropModalToZoneId,
        dropModalToZoneName,
        dropModalIsOpen,
        selectedAppointment,
        dropModalDriverAssignment
      }
    }
    case SET_DROP_MODAL_SELECTED_AREA: {
      return {
        ...state,
        dropModalSelectedArea: action.payload.dropModalSelectedArea
      }
    }
    case SET_DROP_MODAL_SELECTED_SLOT: {
      return {
        ...state,
        dropModalSelectedSlot: action.payload.dropModalSelectedSlot
      }
    }
    case SET_DROP_MODAL_AREA_OPTIONS: {
      return {
        ...state,
        dropModalAreaOptions: action.payload.dropModalAreaOptions
      }
    }
    case SET_CACHE_HITS: {
      return {
        ...state,
        cacheHits: action.payload.cacheHits
      }
    }
    default:
      return state
  }
}
