import React from 'react'
import { useStyletron } from 'baseui'
import {
  HeaderNavigation,
  StyledNavigationItem,
  StyledNavigationList,
  ALIGN
} from 'baseui/header-navigation'
import { HeadingXSmall, ParagraphXSmall } from 'baseui/typography'

const VHeader = ({
  title,
  subtitle,
  leftItems = [],
  centerItems = [],
  rightItems = [],
  overrides
}) => {
  const [css, theme] = useStyletron()
  return (
    <HeaderNavigation
      overrides={{
        Root: {
          style: ({ $theme }) => {
            return {
              paddingTop: $theme.sizing.scale600,
              paddingBottom: $theme.sizing.scale600,
              paddingLeft: $theme.sizing.scale600,
              paddingRight: $theme.sizing.scale600,
              marginLeft: '-16px',
              marginRight: '-16px',
              borderBottomColor: $theme.colors.transparent,
              marginBottom: $theme.sizing.scale800,
              backgroundColor: $theme.colors.white,
              boxShadow:
                '1000px 0px white, -1000px 0px white, 0 1px #dbe6ff, 1000px 1px #dbe6ff, -1000px 1px 0 #dbe6ff',
              [theme.mediaQuery.large]: {
                paddingLeft: 0,
                paddingRight: 0,
                marginLeft: 0,
                marginRight: 0
              }
            }
          }
        },
        ...overrides
      }}>
      <StyledNavigationList $align={ALIGN.left}>
        <StyledNavigationItem className={css({ paddingLeft: '0 !important' })}>
          {subtitle && <ParagraphXSmall margin={`0`}>{subtitle}</ParagraphXSmall>}
          <HeadingXSmall
            margin={`${subtitle ? '0' : theme.sizing.scale400} 0`}
            {...overrides?.title}>
            {title}
          </HeadingXSmall>
        </StyledNavigationItem>
        {leftItems && leftItems.length > 0
          ? leftItems.map((item, index) => (
              <StyledNavigationItem key={index}>{item}</StyledNavigationItem>
            ))
          : ''}
      </StyledNavigationList>
      <StyledNavigationList
        $align={ALIGN.center}
        className={css({
          display: 'none',
          [theme.mediaQuery.large]: {
            display: 'inline'
          }
        })}>
        {centerItems && centerItems.length > 0
          ? centerItems.map((item, index) => (
              <StyledNavigationItem key={index}>{item}</StyledNavigationItem>
            ))
          : ''}
      </StyledNavigationList>
      {rightItems && rightItems.length > 0 ? (
        <StyledNavigationList $align={ALIGN.right} className={css({})}>
          {rightItems.map((item, index) => (
            <StyledNavigationItem key={index}>{item}</StyledNavigationItem>
          ))}
        </StyledNavigationList>
      ) : (
        ''
      )}
    </HeaderNavigation>
  )
}
export default VHeader
