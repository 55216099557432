import { styled } from 'baseui'
import { SIZE } from 'baseui/button'
import { primitives } from 'components/homepage/custom-theme'

const TRAILER_STATUS = {
  InDock: 'in-dock',
  Full: 'full',
  Empty: 'empty'
}

export const NotFoundContainer = styled('div', ({ $theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  paddingTop: $theme.sizing.scale1600
}))

export const AssignButtonProps = {
  size: SIZE.mini,
  overrides: {
    BaseButton: {
      style: {
        outline: `1px ${primitives.positive} solid`,
        color: primitives.positive,
        backgroundColor: 'transparent'
      }
    }
  }
}

export const TagTrailerStateProps = status => {
  return {
    overrides: {
      Root: {
        style: () => ({
          backgroundColor:
            TRAILER_STATUS.Full === status
              ? primitives.primary
              : TRAILER_STATUS.InDock === status
              ? primitives.accent
              : primitives.gray,
          color: TRAILER_STATUS.Empty === status ? primitives.primary : primitives.white,
          border: 'none'
        })
      },
      Text: {
        style: () => ({
          fontSize: '9px',
          textTransform: 'capitalize'
        })
      }
    }
  }
}
