import React, { useState, useEffect, useCallback, useContext } from 'react'
import Select from 'components/ui/generic/Select'
import { useDebounce } from 'react-use'
import useIsMounted from 'components/hooks/use-is-mounted'
import { useAppointmentContext } from 'components/contexts/appointment-context'
import { useTranslation } from 'react-i18next'
import { CurrentUserContext } from 'components/homepage/current-user-context'
import { trailerService } from 'components/services/trailer.service'
import { DEFAULT_SHIPPER_SUBDOMAIN } from 'components/constants/default-shipper-subdomain'
import { useFacilityContext } from 'components/contexts/facility-context'
import { TRAILER_EMPTY_STATUS, TRAILER_NOT_DAMAGED_STATE } from '../../../models/Trailer'

const TrailerAutoComplete = ({ appointmentDirection, onChange, trailerNumber, title }) => {
  const { actions, state } = useAppointmentContext()
  const { appointments } = state
  const { setAppointment } = actions
  const isMounted = useIsMounted()
  const [search, setSearch] = useState(trailerNumber ? [{ label: trailerNumber, id: -1 }] : [])
  const [loading, setLoading] = useState(false)
  const [hits, setHits] = useState([])
  const [tempSearch, setTempSearch] = useState([])
  const { t } = useTranslation()
  const { currentUser } = useContext(CurrentUserContext)
  const subdomain = window.location.hostname.split('.')[0]

  const { state: facilityState } = useFacilityContext()
  const { facility } = facilityState

  const convertToSelect = array => {
    return Array.isArray(array) ? array.map(item => ({ ...item, label: item.number })) : []
  }

  const fetchTrailersOpenScheduling = async () => {
    setLoading(true)
    if (!currentUser && facility?.id) {
      const [hits, status] = await trailerService.getTrailerOpenScheduling(
        subdomain === 'localhost' ? DEFAULT_SHIPPER_SUBDOMAIN : subdomain,
        facility?.id,
        search[0]?.label
      )
      if ([200, 304].includes(status)) {
        setHits(convertToSelect(hits))
      }
    }
    setLoading(false)
  }

  const fetchRecords = useCallback(async () => {
    if (!currentUser) {
      fetchTrailersOpenScheduling()
    } else {
      setLoading(true)
      const [json, status] = await trailerService.getTrailers()
      if ([304, 200].includes(status) && isMounted.current) {
        setHits(convertToSelect(json))
      }
      setLoading(false)
    }
  }, [isMounted, facility?.id])

  const searchRecords = useCallback(async () => {
    if (!currentUser) {
      fetchTrailersOpenScheduling()
    } else {
      setLoading(true)
      const [{ hits }, status] = await trailerService.searchTrailers(search[0]?.label)
      if ([200, 304].includes(status) && isMounted.current) {
        setHits(convertToSelect(hits))
      }
      setLoading(false)
    }
  }, [search, isMounted])

  const handleNumberChange = useCallback(
    option => {
      const trailer = appointments[appointmentDirection]?.trailer || {}
      setSearch(option)
      setAppointment(
        {
          ...appointments[appointmentDirection],
          trailer: {
            number: option[0]?.label,
            status: option[0]?.status || trailer.status || TRAILER_EMPTY_STATUS,
            state: option[0]?.state || trailer.state || TRAILER_NOT_DAMAGED_STATE
          }
        },
        appointmentDirection
      )
    },
    [appointmentDirection, setAppointment, appointments]
  )

  useDebounce(searchRecords, 200, [search])

  useEffect(() => {
    fetchRecords()
  }, [facility?.id])

  useEffect(() => {
    if (isMounted.current) {
      fetchRecords()
    }

    return () => {
      isMounted.current = false
    }
  }, [fetchRecords, isMounted])

  return (
    <Select
      isLoading={loading}
      creatable
      deleteRemoves
      backspaceClearsInputValue
      label={t('Appointments.CreateAppointmentModal.Fields.Trailer.Trailer.Label.Text', {
        type: title
      })}
      labelKey="label"
      valueKey="id"
      value={search}
      onOpen={() => {
        setTempSearch(search)
        if (!search) {
          fetchRecords()
        }
      }}
      onClose={() => {
        if (!search) {
          setSearch(tempSearch)
        }
      }}
      onChange={({ value, option, type }) => {
        switch (type) {
          case 'select':
            handleNumberChange(value)
            break
          case 'clear':
            setAppointment({ number: '' }, appointmentDirection)
            setSearch([])
            break
        }
      }}
      options={hits}
    />
  )
}

export default TrailerAutoComplete
