import React, { useState, useEffect } from 'react'
import Header from 'components/ui/generic/Header'
import { useTranslation } from 'react-i18next'
import { PurchaseOrderLabelsContext } from 'components/pages/CreateAppointmentForExternalUsers'
import authenticatedFetch from 'components/utils/authenticated-fetch'
import { DEFAULT_SHIPPER_SUBDOMAIN } from '../../constants/default-shipper-subdomain'
import { Card, StyledBody } from 'baseui/card'
import { Block } from 'baseui/block'

import { AppointmentCard } from 'components/components/AppointmentCard'
import Divider from '../../ui/generic/Divider'
import { Link } from 'react-router-dom'
import SearchBar from './search-bar'
import { facilityService } from 'components/services'
import { ParagraphSmall } from 'baseui/typography'

const UpdateAppointmentOpenSchedulingSearch = () => {
  const subdomain = window.location.hostname.split('.')[0]
  const [appointments, setAppointments] = useState<any>({})
  const [mainAppointment, setMainAppointment] = useState(null)
  const [currentPage, setCurrentPage] = useState(1)

  const { t } = useTranslation()

  useEffect(() => {
    facilityService
      .openSchedulingFacilities(subdomain === 'localhost' ? DEFAULT_SHIPPER_SUBDOMAIN : subdomain)
      .then(([facilityLabels, _status]) => {
        if (facilityLabels.length >= 1) {
          setMainAppointment(facilityLabels[0])
        }
      })
      .catch(console.log)
  }, [])

  return (
    <>
      <PurchaseOrderLabelsContext.Provider
        value={{
          purchaseOrderLabels:
            mainAppointment &&
            mainAppointment.appointmentPreference &&
            mainAppointment.appointmentPreference.purchase_order_field_names
        }}>
        <Header title={t('HeaderNavigation.NavBarLinks.UpdateAppointment.Text')} />
        <Block maxWidth={['100%', '100%', '80%', '60%']}>
          <Card
            overrides={{
              Root: {
                style: {
                  backgroundColor: 'white'
                }
              }
            }}>
            <StyledBody>
              <Block maxWidth={['100%', '100%', '70%', '60%']}>
                <SearchBar
                  setAppointments={setAppointments}
                  setCurrentPage={setCurrentPage}
                  currentPage={currentPage}
                />
              </Block>
            </StyledBody>
            <Divider />
            <Block
              marginTop="scale800"
              maxWidth={['100%', '100%', appointments.total > 0 ? '70%' : '100%']}>
              {appointments.total > 0 ? (
                appointments?.results?.map((appointment: any) => (
                  <Link
                    style={{
                      textDecoration: 'none'
                    }}
                    to={`/openscheduling/${appointment.id}/schedule`}>
                    <AppointmentCard appointment={appointment} />
                  </Link>
                ))
              ) : (
                <Block display="flex" justifyContent="center" alignItems="center">
                  <ParagraphSmall
                    overrides={{
                      Block: {
                        style: () => ({
                          color: '#6f7480'
                        })
                      }
                    }}>
                    {t('Common.Errors.AppointmentsNotFound.Text')}
                  </ParagraphSmall>
                </Block>
              )}
            </Block>
          </Card>
        </Block>
      </PurchaseOrderLabelsContext.Provider>
    </>
  )
}

export default UpdateAppointmentOpenSchedulingSearch
